<template>
  <div>
    <v-card class="mb-7">
      <v-toolbar
          color="primary"
          dark
      >
          <v-card-title>
              <span class="headline">Historique des évènements</span>
          </v-card-title>
      </v-toolbar>
     
      <v-card-text class="pb-0">
          <v-list class="py-0 overflow-y-auto" max-height="150px" v-if="list.length > 0">
            <v-list-item-group
                color="primary"
            >
                <v-list-item
                  v-for="(item, i) in list.slice().reverse()"
                  :key="i"
                  :to="{ name: 'events', query: { event: item.id }}"
                  class="pa-0 px-3"
                >
                    <v-list-item-content class="py-0">
                        <v-list-item-title><span class="font-weight-black">{{ item.name }}</span> - <span class="text-caption">Le {{ item.dateF }} (Terminé)</span></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <div v-else>
          <span>Vous n'avez participé à aucun évènement</span>
        </div>
      </v-card-text>

      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              type="submit"
              link
              :to="'/evenements'"
          >
              Voir 
          </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Historique',
    props: {
        events: Array,
    },
    data: () => ({
      noEvent: false,
      list: [],
      items: [
        { text: 'Réunion' },
        { text: 'Conseil public' },
        { text: 'Conseil' },
        { text: 'Conseil' },
        { text: 'Conseil' },
      ],
    }),
    mounted: function () {
      this.sortEvents();
    },
    methods: {
      sortEvents() {
        this.events.forEach((value) => {
          if (value.status == 0) {
            this.list.push(value);
          }
        });
      }
    }
  }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
