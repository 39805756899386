<template>
  <v-row>
    <v-col class="pa-0">
      <!-- Dialog Details -->
    <v-dialog v-model="dialogShow" max-width="1000px">
      <v-card>
        <v-toolbar
          color="primary"
          dark
        ><v-card-title class="text-h5">Détail de l'évènement {{ editedItem.name }}</v-card-title></v-toolbar>
        <v-card-text class="py-5">
          <v-row>
            <v-col md="6" sm="12">
              <Info :eventData="editedItem"></Info>
              <Document :eventData="editedItem" ref="document" @successAlertF="successAlertF($event)"></Document>
              <v-row v-if="editedItem.status == 0 && $globalData.isAdmin == true">
                <v-col cols="12">
                  <h2 class="my-5">Résultats des votes</h2>
                  <v-row>
                    <v-col cols="12">
                      <DownloadVote ref="download" class="icons-dl-votes" :eventData="editedItem"></DownloadVote>
                    </v-col>
                    <v-col cols="12">
                      <ExportPresences ref="exportPresences" :eventId="editedItem.id" :eventName="editedItem.name"></ExportPresences>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" sm="12">
              <Participant ref="list" :eventData="editedItem" @successAlertF="successAlertF($event)"></Participant>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="closeShow">Retour</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Edit -->
    <v-dialog v-model="dialog" max-width="1400px">
      <v-row no-gutters>
        <v-col>
          <v-card height="100%">
            <v-form ref="form" @submit.prevent="save" lazy-validation>
              <v-toolbar
                color="primary"
                dark
              >
                <v-card-title class="headline">Editer mon évènement</v-card-title>
              </v-toolbar>

              <v-card-text>
                <ErrorAlert ref="ErrorAlert"></ErrorAlert>
                <v-col>
                  <v-row>
                    <v-col cols="12" >
                      <v-text-field
                        v-model="editedItem.name"
                        :rules="rules.required"
                        label="Nom"
                        :disabled="editedItem.status == 0"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-select
                        :items="types"
                        v-model="editedItem.type"
                        :rules="rules.required"
                        label="Type"
                        :disabled="editedItem.status == 0"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-select
                        :items="rooms"
                        item-text="name"
                        v-model="editedItem.room"
                        :rules="rules.required"
                        label="Salon"
                        :disabled="editedItem.status == 0"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-menu
                          v-model="menuDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="computedDate"
                                label="Date"
                                append-icon="mdi-calendar"
                                :rules="rules.required"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="date = null"
                                :disabled="editedItem.status == 0"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="editedItem.date.date"
                        @input="menuDate = false"
                        :min="getCurrentDate()"
                        :disabled="editedItem.status == 0"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4">
                      <!-- Selection heure de l'évènement -->
                      <v-text-field
                          v-model="editedItem.time"
                          label="Heure"
                          :rules="rules.required"
                          type="time"
                          :disabled="editedItem.status == 0"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <!-- Selection durée de l'event -->
                      <v-text-field
                          v-model="editedItem.duration"
                          label="Durée"
                          :rules="rules.required"
                          type="time"
                          :disabled="editedItem.status == 0"
                      ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                            v-model="editedItem.fileBBB"
                            accept="*"
                            show-size
                            label="Image de présentation BBB"
                            append-icon="mdi-paperclip"
                            prepend-icon=""
                            :disabled="editedItem.status == 0"
                        ></v-file-input>
                        </v-col>

                        <v-col cols="12">
                        <v-textarea
                            v-model="editedItem.description"
                            rows=3
                            name="input-7-1"
                            label="Description"
                            :disabled="editedItem.status == 0"
                        ></v-textarea>
                        </v-col>
                  </v-row>
                </v-col>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="secondary" type="submit">
                  Modifier
                </v-btn> -->
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
        <v-col>
          <v-card height="100%">
            <v-toolbar
              color="primary"
              dark
            >
              <v-card-title class="headline">Modifier les participants</v-card-title>
            </v-toolbar>

            <v-card-text>
              <ErrorAlert ref="ErrorAlert"></ErrorAlert>
              <v-col>
                <v-data-table
                  v-model="editParticipant"
                  :headers="headers"
                  :items="listEditParticipants"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 15, 20, 50]
                  }"
                  :items-per-page="10"
                  checkbox-color="secondary"
                  show-select
                  :disable-sort="$vuetify.breakpoint.smAndDown"
                >
                  <template v-slot:[`item.fullname`]="{ item }">
                    {{ item.name }} {{ item.firstname }}
                  </template>
                  <!-- <template v-slot:[`item.data-table-select`]="{ item }">
                    true
                  </template> -->
                  <!-- <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="sendEmail(item)"> mdi-email </v-icon>
                  </template> -->
                </v-data-table>
              </v-col>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close"> Retour</v-btn>
              <v-btn color="secondary" @click="save" :disabled="editedItem.status == 0">
                Sauvegarder
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
    <!-- Dialog Delete -->
    <v-dialog v-model="dialogDelete" :max-width="$vuetify.breakpoint.xsOnly ? '100%' : '50%'">
      <v-card class="d-flex flex-column justify-center">
        <v-card-title class="text-h5 mx-auto">Êtes-vous sûr de vouloir supprimer cet évènement ?</v-card-title>
        <v-card-text style="text-align:center"><span>En supprimant un évènement, vous supprimerez tous les votes et leurs résultats.</span></v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="secondary" text @click="closeDelete">Retour</v-btn>
          <v-btn color="secondary" @click="deleteItemConfirm">Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Desktop version -->
    <v-sheet color="white"
      elevation="4"
      rounded
      shaped
      class="pa-5"
      v-if="!$vuetify.breakpoint.smAndDown"
      >
    <SuccessAlert ref="SuccessAlert"></SuccessAlert>

    <v-data-table
      :headers="headersEvents"
      :items="events"
      fixed-header	
      locale="fr"
    >
      <template v-slot:top>
        <v-toolbar flat height="0">
        </v-toolbar>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getColor(item.status)"
          :style="
            item.status == 1 ? {'box-shadow': '1px 1px 7px 3px #4CAF50'} : {}
          "
          dark
        >
          {{ getNameEtat(item.status) }}
        </v-chip>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date }} à {{ item.time }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" title="Voir le détail" @click="showItem(item)"> mdi-calendar </v-icon>
        <v-icon small class="mr-2" title="Éditer" @click="editItem(item)" v-if="$globalData.isAdmin == true && item.status != 0"> mdi-pencil </v-icon>
        <v-icon small title="Supprimer" @click="deleteItem(item)" v-if="$globalData.isAdmin == true"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="secondary"> Réinitialiser </v-btn>
      </template>
    </v-data-table>
    </v-sheet>
    <!-- Mobile version  -->
    <div style="overflow-y: auto; height:50vh;" v-if="$vuetify.breakpoint.smAndDown" >
      <v-data-iterator
        :items="events"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        no-data-text="Vous n'avez aucun évènement."
        class="mb-15"
        hide-default-footer
      >
        <template v-slot:item="props">
          <v-card 
            class="my-5"
            hover
            @click="showItem(props.item)"
          >
            <v-toolbar color="primary" dark>
              <v-card-title>{{props.item.name}}</v-card-title>
            </v-toolbar>
            <v-card-text>
              {{props.item.room}}<br>{{props.item.dateF}}<br>{{props.item.durationF}}
            </v-card-text>
            <v-card-actions>
              <v-chip
                :color="getColor(props.item.status)"
                :style="
                  props.item.status == 1 ? {'box-shadow': '1px 1px 7px 3px #4CAF50'} : {}
                "
                dark
              >
                {{ getNameEtat(props.item.status) }}
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn icon title="Voir le détail" @click="showItem(props.item)">
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
              <v-btn icon title="Éditer" @click="editItem(props.item)" v-if="$globalData.isAdmin == true && props.item.status == 2">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon title="Supprimer" @click="deleteItem(props.item)" v-if="$globalData.isAdmin == true">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:footer>
          <v-row
            class="my-3"
            align="center"
            justify="center"
            style="bottom:0; width:100%;"
          >
            <span
              class="mr-4
              grey--text"
            >
              Page {{ page }} sur {{ numberOfPages }}
            </span>
            <v-btn
              fab
              small
              color="secondary"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              color="secondary"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </div>
    </v-col>
  </v-row>
</template>

<script>
import Participant from "./Show/Participant";
import Info from "./Show/Info";
import Document from "./Show/Document";
import DownloadVote from '../vote/DownloadVote';
import ExportPresences from './ExportPresences';

import SuccessAlert from "../alerts/Success.vue"
import ErrorAlert from "../alerts/Error.vue"

import axios from "axios";

export default {
  name: "Table",
  components: { Participant, Info, Document, DownloadVote, ExportPresences, SuccessAlert, ErrorAlert },
  props: {
    eventData: Object,
    events: Array,
    rooms: Array
  },
  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    dialogShow: false,
    types: ['Visio-conférence', 'Webinaire'],
    time: null,
    menuDate: false,
    menuTime: false,
    menuDuration: false,
    duration: '30',
    listEditParticipants: [],
    editParticipant: [],
    DurationHours: [
        '15mn',
        '30mn',
        '45mn',
        '1h',
        '1h30',
        '2h',
        '2h30',
        '3h',
        '3h30',
        '4h',
        '4h30'
      ],
    headers: [
      { text: "", sortable: false, value: "data-table-select", class: 'headerColor' },
      {
        text: "Nom / Prénom",
        align: "center",
        value: "fullname",
        class:'primary white--text'
      },
    ],
    headersEvents: [
      { text: "Etat", value: "status", class:"primary white--text" },
      { text: "Evènements", value: "name", class:"primary white--text" },
      { text: "Salons", value: "room", class:"primary white--text" },
      { text: "Date de session", value: "dateF", class:"primary white--text" },
      { text: "Durée", value: "duration", class:"primary white--text" },
      { text: "Type d'évènement", value: "type", class:"primary white--text" },
      { text: "Actions", value: "actions", sortable: false, class:"primary white--text" },
    ],
    rules: {
      required: [(value) => !!value || "Requis"],
    },
    members: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      room: "",
      date: "",
      duration: "",
      fileBBB: "",
      type: "",
    },
    defaultItem: {
      name: "",
      room: "",
      date: "",
      duration: "",
      fileBBB: "",
      type: "",
    },
    itemsPerPageArray: [4, 8, 12],
    page: 1,
    itemsPerPage: 5
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Créer un membre" : "Éditer";
    },
    formButton() {
      return this.editedIndex === -1 ? "Créer" : "Éditer";
    },
    computedDate () {
        const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
        "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"
        ];
        var day = "";
        if (this.editedItem.date.date != null) {
          var newDate = new Date(this.editedItem.date.date);
          day = 'Le '+newDate.getDate()+' '+monthNames[newDate.getMonth()]+' '+newDate.getFullYear();
        }
      return day;
    },
    numberOfPages () {
      return Math.ceil(this.events.length / this.itemsPerPage)
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogShow(val) {
      val || this.closeShow();
    },
  },
  methods: {
    toMeeting (id) {
      this.$router.push({ name: 'meeting', params: { id: id }, query: { method: 'open' } })
    },
    editItem(item) {
      this.listParticipantsRequest(item.id);
      this.editedIndex = this.events.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    showItem(item) {
      this.editedIndex = this.events.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogShow = true;
      
      if (this.$refs.list && this.$refs.document) {
        this.$refs.list.listParticipants(item.id);
        this.$refs.document.loadDocuments(item.id);
        if(this.$refs.download) {
          this.$refs.download.setEventId(item.id);
        }
      }
    },

    deleteItem(item) {
      this.editedIndex = this.events.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    listParticipantsRequest(idEvent) {
      this.listEditParticipants = [];
      this.editParticipant = [];
      axios({
        method: "get",
        url: this.apiUrl + "/evenements/list/participants",
        headers: {
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        },
        params: {
          idEvent: idEvent
        },
      }).then(
        (response) => {
          this.listEditParticipants = response.data
          this.listEditParticipants.forEach((value) => {
            if (value.isParticipant == true) {
              this.editParticipant.push(value);
            }
          });
        },
        (error) => {
          this.globalErrorMethod(error)
        }
      );
    },

    deleteItemConfirm() {
      axios({
        method: "delete",
        url: this.apiUrl + "/evenements",
        headers: {
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        },
        data: {
          id: this.editedItem.id
        },
      }).then(
        (response) => {
          this.events.splice(this.editedIndex, 1);
          this.$emit('refreshData');
          this.closeDelete();
          this.$refs.SuccessAlert.popAlert(response.data);
        },
        (error) => {
          this.globalErrorMethod(error)
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
        }
      );
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeShow() {
      this.dialogShow = false;
    },
    
    successAlertF(event) {
      this.dialogShow = false;
      this.$refs.SuccessAlert.popAlert(event);
    },
    async uploadDocument(idEvent, type, file, send = false) {
      var data = new FormData()
      data.append("file", file);
      await axios.post(this.apiUrl+'/documents/'+idEvent, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        },
        params: {
          type: type
        }
      })     
      .then(response => {

        this.response = response
        this.e1 = 1,
        this.$emit('refreshData'),
        this.resetForm(),
        this.close();
        if (send == true) {
          this.sendMails(idEvent)
        }
      })
      .catch(error => {
        this.globalErrorMethod(error)
      });
    },
    save() {
      // Si form Edit
      if (this.editedIndex > -1) {

        axios({
          method: "put",
          url: this.apiUrl + "/evenements",
          headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
          },
          data: {
            data: this.editedItem,
            participants: this.editParticipant
          },
        }).then(
          (response) => {
            this.$refs.SuccessAlert.popAlert(response.data);
            Object.assign(this.events[this.editedIndex], this.editedItem);
            if(this.editedItem.fileBBB) {
              this.uploadDocument(this.editedItem.id, 'fileBBB', this.editedItem.fileBBB).then(() => {})
            }
            this.dialog = false;
            this.$emit('refreshData');
            this.$refs.form.reset();
          },
          (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
      }
    },
    getColor (status) {
      if (status == 0) return 'grey'
      else if (status == 1) return 'green'
      else if (status == 2) return 'primary'
      else return 'red'
    },

    getNameEtat (status) {
      if (status == 0) return 'Terminé'
      else if (status == 1) return 'En cours'
      else if (status == 2) return 'À venir'
      else return 'Erreur'        
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    checkLengthDate(option) {
        if (option < 10) {
            option = '0'+option;
            return option;
        } else {
            return option;
        }
    },
    getCurrentDate() {
        const actualDate = new Date();
        const actualDateFormat = actualDate.getFullYear()+'-'+this.checkLengthDate(actualDate.getMonth()+1)+'-'+this.checkLengthDate(actualDate.getDate());
        return actualDateFormat;
    }
  },
};
</script>