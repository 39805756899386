<template>
  <v-row>
    <v-col class="pa-0">
      <v-sheet color="white"
      elevation="4"
      rounded
      shaped
      class="pa-5 mb-4"
      >
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            color="grey darken-2"
            @click="setToday"
          >
            Aujourd'hui
          </v-btn>
          <v-btn
            fab
            text
            x-small
            class="mx-1"
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            class="mx-1"
            x-small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Jours</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semaines</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mois</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>Sur 4 jours</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="secondary"
          :events="eventsData"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            flat
            max-width="400"
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-img
                max-height="25"
                max-width="25"
                src="@/assets/images/logo_small.png"
                class="ml-4"
              ></v-img>
              <v-card-title v-html="selectedEvent.name" ></v-card-title>
            </v-toolbar>
            <v-card-text>
              Type : <span v-html="selectedEvent.type"></span><br>
              Salon : <span v-html="selectedEvent.room"></span><br>
              Durée : <span v-html="selectedEvent.duration"></span><br>
              Description : <span v-html="selectedEvent.description"></span>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>

export default {
    name: 'Calendar',
    props: {
      events: Array,
    },
    data: () => ({
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mois',
        week: 'Semaines',
        day: 'Jours',
        '4day': 'Sur 4 jours',
      },
      eventsData: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }),
    mounted () {
      this.$refs.calendar.checkChange()
    },
    methods: {
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      updateRange (data) { // A AMELIORER ! cette route est déjà appelé dans le parent EventPage, il faut arriver à le récup pour éviter le doublon d'appel
        const eventsData = []
        const fullData = data;

        Object.keys(fullData).forEach(function (key){
          eventsData.push({
            name: fullData[key].name,
            start: fullData[key].start,
            end: fullData[key].end,
            type: fullData[key].type,
            room: fullData[key].room,
            duration: fullData[key].duration,
            description: fullData[key].description,
            color: 'primary'
          })
        });

        this.eventsData = eventsData
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
}
</script>

<style lang="scss">

  @media (max-width: 568px) {

    .v-toolbar__content{
      padding: 0px!important;

      .v-btn{
        font-size: 0.6rem!important;
        padding: 0 4px!important;
        margin: 2px 0!important;
      }
    }
  }
</style>