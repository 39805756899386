<template>
    <div>
        <div class="pb-4">Bienvenue sur la page d'accueil de Stromeety !</div>

        <div>
            <h4 class="py-2">Qu'est-ce qu'un évènement ?</h4>
            <p>Un évènement est une réunion auquel vous participez et qui est prévu à une date et une heure fixe. que vous pouvez retrouver en cliquant sur l'onglet "Evènements" sur le menu latéral à gauche. <br>
            Pour plus d'informations sur un évènement, n'hésitez pas à contacter l'organisateur.</p>
        </div>

        <div>
            <h4 class="py-2">Qu'est-ce qu'un salon ?</h4>
            <p>Un salon correspond à une salle dans laquelle se déroule les évènements auquels vous participez.<br>
            Vous pouvez accéder à la liste des salons et des évènements qui y sont lié dans l'onglet "Salons" dans le menu latéral gauche.</p>
        </div>

        <v-alert
        type="info"
        border="left"
        text
        >
            <span class="text--secondary">Si un évènement est en cours, vous retrouverez un <b>bandeau</b> en haut de cette page qui vous redirigera directement dans le salon et dans l'évènement en cours.</span>
        </v-alert>
        <v-timeline
            dense
            clipped
            class="mt-5"
        >
            <!-- Evènements à venir -->
            <v-timeline-item
            color="primary"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    Mes évènements à venir
                </h3>
                <div>
                    Ce bloc vous permet de visualiser vos évènements à venir et leurs informations. Ils sont affiché du plus tôt en haut au plus tard en bas.
                </div>
            </div>
            </v-timeline-item>
            <!-- Historique des évènements -->
            <v-timeline-item
            color="primary"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    Historique des évènements
                </h3>
                <div>
                    Vous pouvez visualiser tous les évènements qui ont déjà eu lieu et auquels vous avez été invité.
                </div>
            </div>
            </v-timeline-item>
            <!-- Liste des salons -->
            <v-timeline-item
            color="primary"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    Mes salons
                </h3>
                <div>
                    Vous retrouverez la liste de tous les salons ainsi qu'au nombre d'évènements qui sont enregistrés pour chacun d'entre eux.
                </div>
            </div>
            </v-timeline-item>
        </v-timeline>

    </div>
</template>

<script>
export default {
    name: 'HomeHelp'
}
</script>
