<template>
    <div>
        <h1>Mon compte</h1>
        <v-row justify="center">
          <v-col cols="11">
                <v-row>
                    <v-col cols="12" md="6" sm="12">
                        <Info></Info>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <Password></Password>
                    </v-col>
                </v-row>
                <v-row v-if="$globalData.isAdmin == true">
                    <v-col cols="12">
                        <StreamingLinks></StreamingLinks>
                    </v-col>    
                </v-row>    
            </v-col>
        </v-row>
        <HelpPage v-if="!$vuetify.breakpoint.smAndDown"></HelpPage>
    </div>
</template>

<script>
import Info from './settings/Info'
import Password from './settings/Password'
import StreamingLinks from './settings/StreamingLinks'
import HelpPage from './HelpPage'

export default {
    name: 'SettingPage',
    components: { 
        Info,
        Password,
        StreamingLinks,
        HelpPage
    }
}
</script> 