import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import fr from 'vuetify/src/locale/fr.ts';
// import colors from 'vuetify/lib/util/colors'

export default new Vuetify({
    lang: {
      locales: { fr },
      current: 'fr',
    },
    theme: {
      themes: {
        light: {
          primary: '#006EB2', // FF5F34
          secondary: '#4DC1F1', // 
          accent: '#ff7652', // 006EB2
        },
      },
    },
  })