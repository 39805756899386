<template>
    <div>
        <v-app-bar
        color="primary"
        v-if="$vuetify.breakpoint.smAndDown"
        fixed
        dark
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <HelpPage 
                v-if="
                    this.$route.name == 'home' || 
                    this.$route.name == 'events' || 
                    this.$route.name == 'vote' || 
                    this.$route.name == 'setting'
                "
            ></HelpPage>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            class="primary"
            dark
            :permanent="!$vuetify.breakpoint.smAndDown"
            :temporary="$vuetify.breakpoint.smAndDown"
            
            app
        >
        <v-list>    
            <v-list-item class="px-2">
                <v-list-item-avatar rounded="0">
                    <v-img src="@/assets/images/logo_white.png" alt="logo stromeety"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ firstname }} {{ name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ organizationName }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list v-if="isAdminUser === false">
            <v-list-item
            v-for="item in navUser"
            :key="item.title"
            @click="linkUrl(item.link)"
            link
            >
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-list v-if="isAdminUser === true">
            <v-list-item
            v-for="item in navAdmin"
            :key="item.title"
            @click="linkUrl(item.link)"
            link
            >
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        <!-- <div>
            <v-divider></v-divider>
            <div class="votes" v-if="this.$route.name === 'meeting'">
                <Vote :id="idEvent"></Vote>
            </div>
        </div> -->

        </v-navigation-drawer>

        <v-dialog
        v-model="dialog"
        persistent
        max-width="650"
        >
        <v-card>
            <v-card-title class="text-h5">
            Voulez vous ouvrir le lien dans un nouvel onglet ?
            </v-card-title>
            <v-card-text></v-card-text>
            <v-card-actions :style="$vuetify.breakpoint.xsOnly ? 'flex-wrap: wrap !important;' : ''">
                <v-btn
                    color="secondary"
                    text
                    @click="dialog = false"
                    :class="$vuetify.breakpoint.xsOnly ? 'mobileDialogLeaveMeeting' : ''"
                >
                    Rester
                </v-btn>
                <v-btn
                    color="error"
                    @click="redirect(urlNewWindow)"
                    :class="$vuetify.breakpoint.xsOnly ? 'mobileDialogLeaveMeeting' : ''"
                >
                    Quitter
                </v-btn>
                <v-btn
                    color="secondary"
                    @click="redirectNewWindow(urlNewWindow)"
                    :class="$vuetify.breakpoint.xsOnly ? 'mobileDialogLeaveMeeting' : ''"
                >
                    Ouvrir dans un nouvel onglet
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<script>
import HelpPage from '../HelpPage'

export default {
    name: 'Header',
    components: {
        HelpPage
    },
    data () {
        return {
            dialog : false,
            urlNewWindow: null,
            items: [
                { title: 'Accueil', icon: 'mdi-view-dashboard', link: '/', isAdmin: false},
                { title: 'Evènements', icon: 'mdi-calendar', link: '/evenements', isAdmin: false},
                { title: 'Votes', icon: 'mdi-vote', link: '/votes', isAdmin: true},
                { title: 'Salons', icon: 'mdi-sofa', link: '/salons', isAdmin: false},
                { title: 'Membres', icon: 'mdi-account-group', link: '/membres', isAdmin: true},
                { title: 'Mon compte', icon: 'mdi-cog', link: '/parametres', isAdmin: false},
                { title: 'Se déconnecter', icon: 'mdi-login-variant', link: '/login', isAdmin: false},
            ],
            name: '',
            firstname: '',
            isAdminUser: null,
            organizationName: '',
            drawer: false
        }
    },
    computed: {
        navUser: function () {
            return this.items.filter(i => i.isAdmin === false)
        },
        navAdmin: function () {
            return this.items
        },
        idEvent: function () {
            return this.$route.params.id
        }
    },
    created () {
      const user = JSON.parse(localStorage.getItem('user'));
      this.isAdminUser = this.$globalData.isAdmin;
      this.name = user.name;
      this.firstname = user.firstname;
      this.organizationName = user.organization[0].name
    },
    methods: {
        linkUrl(item) {
            if (this.$route.name === 'meeting') {
                this.dialog = true;
                this.urlNewWindow = item;
            }
            else {
                this.$router.push({path:item}).catch(()=>{});
            }
        },
        redirectNewWindow(item) {
            let route = this.$router.resolve({path: item});
            window.open(route.href, '_blank');
            this.dialog = false;
        },
        redirect(item) {
            this.dialog = false;
            this.$router.push({path:item});
        },
    }
}
</script>