<template>
    <v-sheet
        color="white"
        elevation="4"
        rounded
        shaped
        class="pa-4 mb-2"
    >

        <h2 id="infoTitle">{{ title }}</h2>

        <v-divider class="my-5"></v-divider>

        <SuccessAlert ref="SuccessAlert"></SuccessAlert>
        <ErrorAlert ref="ErrorAlert"></ErrorAlert>

        <v-form
        ref="form"
        @submit.prevent="valid"
        lazy-validation
        >
            <v-text-field
                v-model="editedItem.name"
                label="Nom"
                :loading="loaderInfo"
                :disabled="loaderInfo"
                required
            ></v-text-field>

            <v-text-field
                v-model="editedItem.firstname"
                label="Prénom"
                :loading="loaderInfo"
                :disabled="loaderInfo"
                required
            ></v-text-field>

            <v-text-field
                v-model="editedItem.email"
                :rules="emailRules"
                label="Adresse e-mail"
                :loading="loaderInfo"
                :disabled="loaderInfo"
                required
            ></v-text-field>

            <v-text-field
                v-model="editedItem.phone"
                :rules="[phoneRules.required, phoneRules.min, phoneRules.integer]"
                label="Téléphone"
                :loading="loaderInfo"
                :disabled="loaderInfo"
                required
            ></v-text-field>

            <v-text-field
                v-model="editedItem.city"
                label="Ville"
                :loading="loaderInfo"
                :disabled="loaderInfo"
                required
            ></v-text-field>
            <v-btn color="secondary" type="submit" large>Modifier</v-btn>
        </v-form>
    </v-sheet>
</template>
<script>
import axios from 'axios';
import SuccessAlert from "../alerts/Success.vue"
import ErrorAlert from "../alerts/Error.vue"

export default {
    name: 'Info',
    components: { 
        SuccessAlert, 
        ErrorAlert
    },
    data () {
        return {
            name: '',
            firstname: '',
            email: '',
            phone: '',
            city: '',
            title: "Modifier mes informations",
            editedItem: {
                name: '',
                firstname: '',
                email: '',
                phone: '',
                city: ''
            },
            emailRules: [ 
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email invalide.'
                ],
            phoneRules: {
                required: value => !!value || 'Requis.',
                min: v => v.length == 10 || 'Numéro de téléphone incorrect (10 chiffres nécessaires)',    
                integer: v => Number.isInteger(Number(v)) || 'Numéro de téléphone incorrect',            
            },
            requiredRule: [
                value => !!value || 'Requis.' 
            ],
            loaderInfo: false
        }
    },
    created () {
      this.initialize()
    },
    methods: {
      initialize () {
        const user = JSON.parse(localStorage.getItem('user'));
        this.loaderInfo = true;
        axios({
          method: 'get',
          url: this.apiUrl+'/parametres',
            headers: {
                'STROMEETY-AUTH-TOKEN': this.$globalData.token
            },
            params: {
                email: user.email
            }
        })
        .then((response) => {
            this.editedItem.name = response.data[0].name;
            this.editedItem.firstname = response.data[0].firstname;
            this.editedItem.email = response.data[0].email;
            this.editedItem.phone = response.data[0].phone;
            this.editedItem.city = response.data[0].city;
            this.loaderInfo = false;
        }, (error) => {
            this.globalErrorMethod(error)
        });
      },
      valid() {
          axios({
            method: 'put',
            url: this.apiUrl+'/parametres/infos',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'STROMEETY-AUTH-TOKEN': this.$globalData.token
            },
            data: {
              name: this.editedItem.name,
              firstname: this.editedItem.firstname,
              email: this.editedItem.email,
              phone: this.editedItem.phone,
              city: this.editedItem.city
            }
          })
          .then((response) => {
            this.$refs.SuccessAlert.popAlert(response.data);
          }, (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          });
      },
    }
}
</script>