<template>
    <div>
        <div>
            <h4 class="py-2">Qu'est-ce qu'un évènement ?</h4>
            <p>Un évènement est une réunion auquel vous participez et qui est prévu à une date et une heure fixe. que vous pouvez retrouver en cliquant sur l'onglet "Evènements" sur le menu latéral à gauche. <br>
            Pour plus d'informations sur un évènement, n'hésitez pas à contacter l'organisateur.</p>
        </div>

        <div>
            <h4 class="py-2">Qu'est-ce qu'un salon ?</h4>
            <p>Un salon correspond à une salle dans laquelle se déroule les évènements auquels vous participez.<br>
            Vous pouvez accéder à la liste des salons et des évènements qui y sont lié dans l'onglet "Salons" dans le menu latéral gauche.</p>
        </div>

        <v-timeline
            dense
            clipped
            class="mt-5"
        >
            <!-- Evènements à venir -->
            <v-timeline-item
            color="primary"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    Calendrier
                </h3>
                <div>
                    Le calendrier vous permet de visualiser tous les évènement auxquels vous avez été invité. Vous pouvez afficher plus de détails en cliquant sur un évènement.
                </div>
            </div>
            </v-timeline-item>
            <!-- Historique des évènements -->
            <v-timeline-item
            color="primary"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    Tableau des évènements
                </h3>
                <div>
                    Ce tableau reprend les évènements déjà visible sur la calendrier. 
                    Un état apparaît dans la première colonne, classant ainsi les évènement dans l'ordre suivant : <br>
                    <div>
                        <v-chip color="green" style="box-shadow: 1px 1px 7px 3px #4CAF50" class="ma-3" dark>
                        En cours
                        </v-chip>
                        Désigne les évènements qui sont en cours.
                    </div>
                    <div>
                        <v-chip color="primary" class="ma-3" dark>
                        À venir
                        </v-chip>
                        Désigne les évènements qui ne sont pas encore déroulé.
                    </div>
                    <div>
                        <v-chip color="grey" class="ma-3" dark>
                        Terminé
                        </v-chip>
                        Désigne les évènements passés.
                    </div>
                </div>
                <div>
                    <div class="my-2">Dans la dernière colonne à droite, vous pouvez cliquer sur l'icone <v-icon>mdi-calendar</v-icon> pour accéder à toutes les informations et documents liés à cet évènement.</div>
                    <div v-if="$globalData.isAdmin == true">
                        En tant qu'administrateur, vous disposez de 2 icones vous permettant de modifier un évènement <v-icon>mdi-pencil</v-icon> ou de le supprimer <v-icon> mdi-delete </v-icon>. 
                    </div>
                </div>
            </div>
            </v-timeline-item>
            <!-- Consulter un évènement -->
            <v-timeline-item
            color="primary"
            v-if="$globalData.isAdmin == true"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    <v-icon>mdi-calendar</v-icon> Consulter un évènement <small>(administrateur)</small>
                </h3>
                <div>
                    En tant qu'administrateur, vous avez la possibilité d'exporter la liste des présences. Cette liste vous permet de savoir qui <b>s'est connecté</b> sur le serveur vocal durant l'évènement et non pas simplement la liste des participants.<br>
                    <i>Par exemple, si vous invitez un utilisateur à un évènement, il est considéré comme étant participant. Mais s'il ne participe pas à l'évènement au moment venu, il n'apparaîtra pas dans cette liste des présences. </i>
                </div>
                <div class="mt-5">
                    Sur le tableau à votre droite, vous avez cette fois ci la liste de toutes les personnesque vous avez invité à participer. Vous pouvez à n'importe quel moment décider d'envoyer un mail d'invitation en cochant les destinataires.<br>
                    Par défaut, 5 utilisateurs sont visibles par page, vous pouvez en afficher plus en cliuant sur le menu déroulant en dessous et en sélectionnant 5, 10, 15 ...
                </div>
            </div>
            </v-timeline-item>
            <!-- Modifier un évènement -->
            <v-timeline-item
            color="primary"
            v-if="$globalData.isAdmin == true"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    <v-icon>mdi-pencil</v-icon> Modifier un évènement <small>(administrateur)</small>
                </h3>
                    <v-alert
                    type="info"
                    border="left"
                    class="my-2"
                    text
                    >
                        <span class="text--secondary">Vous ne pouvez modifier un évènement que si son etat est :
                            <v-chip color="primary" class="ml-3" dark>
                            À venir
                            </v-chip></span>
                    </v-alert>
                <div class="mt-3">
                    En tant qu'administrateur, vous avez la possibilité de modifier un évènement si celui ci n'est pas <b>en cours</b> ou <b>terminé</b>.<br>
                    La modification de l'évènement se divise en 2 parties :
                </div>
                <div class="mt-3">
                    <b>Editer mon évènement :</b> Comme son nom l'indique, vous pouvez modifier les informations liés à l'évènement.
                </div>
                <div class="mt-3">
                    <b>Modifier les participants :</b> Ce tableau vous permet de modifier les participants que vous aviez invité lors de la création de l'évènement.<br>
                    Les participants sont les utilisateurs sélectionnés. Pour ajouter ou retirer un participant, il vous suffit de cocher ou décocher sa case et de cliquer sur "Sauvegarder"
                </div>
            </div>
            </v-timeline-item>
            <!-- Supprimer un évènement -->
            <v-timeline-item
            color="primary"
            v-if="$globalData.isAdmin == true"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    <v-icon>mdi-delete</v-icon> Supprimer un évènement <small>(administrateur)</small>
                </h3>
                <div>
                    <v-alert
                    type="info"
                    border="left"
                    class="my-2"
                    text
                    >
                        <span class="text--secondary">Si vous supprimez un évènement, tous les documents, votes et résultats des votes liés à cet évènement seront aussi supprimés. Pour éviter de perdre les votes et leurs résultats, pensez à les exporter en cliquant directement sur <v-icon color="rgba(0, 0, 0, 0.54)">mdi-calendar</v-icon> puis sur le bouton d'export des votes.</span>
                    </v-alert>
                </div>
            </div>
            </v-timeline-item>
        </v-timeline>

    </div>
</template>

<script>
export default {
    name: 'EventHelp'
}
</script>
