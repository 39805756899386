import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import { router } from './router/router';
import JsonExcel from "vue-json-excel";
import VueCookies from 'vue-cookies';

Vue.use(VueRouter);
Vue.use(VueCookies)

Vue.$cookies.config('7d');
// Defined url API
Vue.prototype.apiUrl = 'https://'+window.location.hostname+'/api'
Vue.prototype.apiUrlAuth = 'https://'+window.location.hostname+'/'

Vue.config.productionTip = false;
Vue.prototype.$user = '';
Vue.prototype.$globalData = Vue.observable({
  isAdmin: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).isAdmin : false,
  token: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : false
});
Vue.component("downloadExcel", JsonExcel);
Vue.mixin({
  methods: {
    globalErrorMethod: function (error) {
      if(error.response.status == 403) {
        this.$router.push({ name: 'home'});
      } 
      if(error.response.data.message == 'Invalid credentials.') {
        this.$router.push({ name: 'login'});
      }
      if (error.response.data.detail == 'Access Denied.') {
        this.$router.push({ name: 'home'});
      }
      if (error.response.data.code == 'NOT_REGISTERED') {
        this.$router.push({ name: 'home'});
      }
    },
  },
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
