<template>
    <v-sheet
        color="white"
        elevation="4"
        rounded
        shaped
        class="pa-4 mb-2"
        fill-height
    >
        <h2>Modifier mon mot de passe</h2>

        <v-divider class="my-5"></v-divider>

        <SuccessAlert ref="SuccessAlert"></SuccessAlert>
        <ErrorAlert ref="ErrorAlert"></ErrorAlert>

        <v-form
        ref="form"
        @submit.prevent="valid"
        lazy-validation
        >
            <v-text-field
              v-model="editedItem.password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Mot de passe"
              hint="8 caractères minimum"
              autocomplete="new-password"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.rePassword"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min, passwordConfirmationRule]"
              :type="show2 ? 'text' : 'password'"
              name="input-10-1"
              label="Répéter mot de passe"
              hint="8 caractères minimum"
              autocomplete="new-password"
              counter
              @click:append="show2 = !show2"
            ></v-text-field>

            <v-btn color="secondary" type="submit" large>Modifier</v-btn>
        </v-form>
    </v-sheet>
</template>
<script>
import axios from 'axios';
import SuccessAlert from "../alerts/Success.vue"
import ErrorAlert from "../alerts/Error.vue"

export default {
    name: 'Password',
    components: { 
      SuccessAlert, 
      ErrorAlert
    },
    data () {
        return {
            show1: false,
            show2: false,
            editedItem: {
                password: '',
                rePassword: ''
            },
            rules: {
                required: v => !!v || 'Requis.',
                min: v => v.length >= 8 || 'Min 8 caractères',
            },
        }
    },
    computed: {
        passwordConfirmationRule() {
            return () => (this.password === this.rePassword) || 'Les mots de passe ne correspondent pas.'
        }
    },
    methods: {
      valid() {
          const user = JSON.parse(localStorage.getItem('user'));
          axios({
            method: 'put',
            url: this.apiUrl+'/parametres/password',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'STROMEETY-AUTH-TOKEN': user.token
            },
            data: {
              password: this.editedItem.password,
              rePassword: this.editedItem.rePassword
            }
          })
          .then((response) => {
            this.$refs.SuccessAlert.popAlert(response.data);
          }, (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          });
      },
    }
}
</script>