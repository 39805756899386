<template>
  <div>
    <v-snackbar
     v-model="errorAlertControl"
     color="error"
     :top="top"
     :timeout="timeout"
     :right="right"
    >
      <v-icon dark right>mdi-close-circle</v-icon>
      {{ errorMessageControl }}
    </v-snackbar>
  </div>
</template>

<script>

export default {
  name: "ErrorAlert",
  data: () => ({
    errorAlertControl: false,
    errorMessageControl: 'Une erreur est survenue.',
    timeout: 5000,
    top: true,
    right: true
  }),
  methods: {
    popAlert (message) {
        // Exemple utilisation : this.$refs.ErrorAlert.popAlert('Exemple de pop message');
        this.errorAlertControl = true;
        if (message.lenght == 0) {
          this.errorMessageControl = 'Une erreur est survenue.'
        } else {
          this.errorMessageControl = message
        }
    }
  }
};
</script>
