<template>
  <div>
    <SuccessAlert ref="SuccessAlert"></SuccessAlert>
    <ErrorAlert ref="ErrorAlert"></ErrorAlert>
    <InfoAlert ref="InfoAlert"></InfoAlert>
      <v-btn
        small
        :color="statusLivestream != 1 ? 'primary' : 'red'"
        :loading="statusLivestream == 2"
        :disabled="statusLivestream == 2"
        v-on:click="statusLivestream != 1 ? openDialogStreaming() : dialogCloseStreaming = true"
        style="width:100%; color:white;"
      >
        <v-icon left small> mdi-play </v-icon>
        <span>{{ statusLivestream != 1 ? 'Activer le streaming' : 'Arrêter le streaming' }}</span>
      </v-btn>

    <!-- Activation du streaming -->
    <v-dialog
    v-model="dialogStreamingListe"
    transition="dialog-top-transition"
    max-width="400"
    >
      <v-card>
        <v-toolbar
            color="primary"
            class="text-h6 pl-3"
            dark
        >Sélectionnez la plateforme</v-toolbar>
        <v-card-text class="pa-5">
          <v-btn
            tile
            color="#FF0000"
            style="width:100%; border-radius:8px;"
            :class="links.youtube ? 'my-5' : 'my-5'"
            :loading="statusLivestreamSending"
            :disabled="!links.youtube"
            :dark="links.youtube != null"
            v-on:click="activateLivestream('youtube')"
          >
            <v-icon left>
              mdi-youtube
            </v-icon>
            Youtube
          </v-btn>

          <v-btn
            tile
            color="#6441a5"
            style="width:100%; border-radius:8px;"
            :loading="statusLivestreamSending"
            :disabled="!links.twitch"
            :dark="links.twitch != null"
            v-on:click="activateLivestream('twitch')"
          >
            <v-icon left>
              mdi-twitch
            </v-icon>
            Twitch
          </v-btn>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="secondary"
            text
            @click="dialogStreamingListe = false"
          >
            Retour
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Arrêter le streaming -->
    <v-dialog
    v-model="dialogCloseStreaming"
    persistent
    max-width="650"
    >
    <v-card>
        <v-card-title class="text-h5">
        Êtes vous sûr de vouloir terminer le streaming ?
        </v-card-title>
        <v-card-text>
          <span class="text-small">* Cette action peut prendre plus d'une minute.</span>
        </v-card-text>
        <v-card-actions :style="$vuetify.breakpoint.xsOnly ? 'flex-wrap: wrap !important;' : ''">
            <v-btn
                color="secondary"
                text
                @click="dialogCloseStreaming = false"
                :loading="statusLivestream == 2"
                :class="$vuetify.breakpoint.xsOnly ? 'mobileDialogLeaveMeeting' : ''"
            >
                Retour
            </v-btn>
            <v-btn
                color="error"
                @click="closeLivestream()"
                :loading="statusLivestream == 2"
                :class="$vuetify.breakpoint.xsOnly ? 'mobileDialogLeaveMeeting' : ''"
            >
                Arrêter le streaming
            </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ErrorAlert from "../../alerts/Error.vue"
import SuccessAlert from "../../alerts/Success.vue"
import InfoAlert from "../../alerts/Info.vue"

export default {
  name: "StartStreaming",
  components: {  
    ErrorAlert,
    SuccessAlert,
    InfoAlert
  },
  data: () => ({
    dialogStreamingListe: false,
    dialogCloseStreaming: false,
    statusLivestream: 0, // 0: Hors Ligne. 1: En live. 2: En attente du status
    statusLivestreamSending: false, // 0: Hors Ligne. 1: En live. 2: En attente du status
    links: {
      youtube: null,
      twitch: null
    },
  }),
  created () {
    this.loadStreamingLinks();
  },
  methods: {
    setLivestreamEtat(etat) {
      this.$emit('setEtat', etat);
    },
    setEtat(etat) {
      this.statusLivestream = etat;
    },
    openDialogStreaming() {
      this.dialogStreamingListe = true;
      // Check si le rtmp est deinfed
    },
    loadStreamingLinks () {
      axios({
        method: 'get',
        url: this.apiUrl+'/parametres/rtmp',
          headers: {
              'STROMEETY-AUTH-TOKEN': this.$globalData.token
          }
      })
      .then((response) => {
        if(response.data[0].youtube == '') {
          this.links.youtube = null;
        } else {
          this.links.youtube = response.data[0].youtube;
        }
        if(response.data[0].twitch == '') {
          this.links.twitch = null;
        } else {
          this.links.twitch = response.data[0].twitch;
        }
      }, (error) => {
          this.globalErrorMethod(error)
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
      });
    },
    activateLivestream(platformName) {
      this.statusLivestreamSending = true;
      if (platformName == 'youtube') {
        if (this.links.youtube != null && this.links.youtube.length > 0) {
          this.requestLivestream(platformName);
        } else {
          this.$refs.InfoAlert.popAlert('Vous n\'avez pas configuré le rtmp Youtube dans la page : Mon Compte');
        }
      } else if (platformName == 'twitch') {
        if (this.links.twitch != null && this.links.twitch.length > 0) {
          this.requestLivestream(platformName);
        } else {
          this.$refs.InfoAlert.popAlert('Vous n\'avez pas configuré le rtmp Twitch dans la page : Mon Compte');
        }
      }
    },
    requestLivestream(platformName) {
      this.statusLivestream = 1;
      axios.get(this.apiUrl + "/livestream/"+this.$route.params.id, {
        headers: {
          "STROMEETY-AUTH-TOKEN": this.$globalData.token,
        },
        params: {
          platform: platformName
        }
      })
      .then(
        (response) => {
          this.statusLivestreamSending = false;
          this.$refs.SuccessAlert.popAlert(response.data);
          this.dialogStreamingListe = false;
        },
        (error) => {
          this.globalErrorMethod(error);
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
        }
      );
    },
    closeLivestream() {
      this.statusLivestream = 2;
      axios
        .get(
          this.apiUrl + "/livestream/end/"+ this.$route.params.id,
          {
            headers: {
              "STROMEETY-AUTH-TOKEN": this.$globalData.token,
            },
          }
        )
        .then(
          (response) => {
            this.$refs.SuccessAlert.popAlert(response.data);
            this.statusLivestream = 0;
            this.dialogCloseStreaming = false;
          },
          () => {
            this.dialogCloseStreaming = false;
            this.$refs.ErrorAlert.popAlert('Un problème est survenu.');
          }
        );
    },
  }
};
</script>

<style>
.youtube-btn > .v-btn__content  {
  color:#FF0000;
}
.twitch-btn > .v-btn__content  {
  color:#6441a5;
}
</style>