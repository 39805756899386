<template>
    <div>
        <!-- Cookies détail -->
        <v-dialog
        v-model="alertCookieDetails"
        :max-width="800"
        >
            <v-card class="mx-auto">
                <v-card-text class="py-5 cookie-details" style="text-align:center;">
                    <v-icon center size="70" color="primary"> mdi-cookie </v-icon>
                    <br>
                    <i class="mt-5">Cette politique de cookies a été mise à jour pour la dernière fois le 20 juillet 2021 et s’applique aux citoyens et aux résidents permanents légaux de l’Espace Économique Européen.</i>
                    <!--  -->
                    <h3 class="my-5">Introduction</h3>
                    <p>Notre application, <b>Stromeety</b> utilise des cookies et autres technologies liées (par simplification, toutes ces technologies sont désignées par le terme « cookies »). Des cookies sont également placés par des tierces parties que nous avons engagées. Dans le document ci-dessous, nous vous informons de l’utilisation des cookies sur <b>Stromeety</b>.</p>

                    <!--  -->
                    <h3 class="my-5">Que sont les cookies ?</h3>
                    <p>Un cookie est un petit fichier simple envoyé avec les pages de cette application et <b>stocké par votre navigateur</b> sur le disque dur de votre ordinateur ou d’un autre appareil. Les informations qui y sont stockées peuvent être renvoyées à nos serveurs ou aux serveurs des tierces parties concernées lors d’une visite ultérieure.</p>

                    <!--  -->
                    <h3 class="my-5">Que sont les scripts ?</h3>
                    <p>Un script est un élément de code utilisé pour que notre application <b>fonctionne correctement</b> et de manière interactive. Ce code est exécuté sur notre serveur ou sur votre appareil.</p>

                    <!--  -->
                    <h3 class="my-5">Qu’est-ce qu’une balise invisible ?</h3>
                    <p>Une balise invisible (ou balise web) est un petit morceau de texte ou d’image invisible sur un site web ou une application, utilisé pour suivre le trafic sur un site web. Pour ce faire, diverses données vous concernant <b>sont stockées</b> à l’aide de balises invisibles.</p>

                    <!--  -->
                    <h3 class="my-5">Cookies techniques ou fonctionnels</h3>
                    <p>Certains cookies assurent le <b>fonctionnement correct</b> de certaines parties de l'application et la prise en compte de vos préférences en tant qu’utilisateur. En plaçant des cookies fonctionnels, nous vous facilitons la visite de notre application. </p>
                    <p>Ainsi, vous n’avez pas besoin de saisir à plusieurs reprises les mêmes informations lors de la visite de notre application et, par exemple, les éléments restent dans votre panier jusqu’à votre paiement. Nous pouvons placer ces cookies sans votre consentement.</p>

                    <!--  -->
                    <h3 class="my-5">Consentement</h3>
                    <p>Lorsque vous visitez notre application pour la première fois, nous vous montrerons une fenêtre contextuelle avec une explication sur les cookies. Dès que vous cliquez sur « Enregistrer les préférences » vous nous autorisez à utiliser les catégories de cookies et d’extensions que vous avez sélectionnés dans la fenêtre contextuelle, comme décrit dans la présente politique de cookies. Vous pouvez désactiver l’utilisation des cookies via votre navigateur, mais veuillez noter que notre application pourrait ne plus fonctionner correctement.</p>

                    <!--  -->
                    <h3 class="my-5">Vos droits concernant les données personnelles</h3>
                    <p>Vous avez les droits suivants concernant vos données personnelles :</p>
                    <ul>
                        <li>Vous avez le droit de savoir pourquoi vos données personnelles sont nécessaires, ce qui leur arrivera et combien de temps elles seront conservées.</li>
                        <li>Droit d’accès : vous avez le droit d’accéder à vos données personnelles que nous connaissons.</li>
                        <li>Droit de rectification : vous avez le droit à tout moment de compléter, corriger, faire supprimer ou bloquer vos données personnelles.</li>
                        <li>Si vous nous donnez votre consentement pour le traitement de vos données, vous avez le droit de révoquer ce consentement et de faire supprimer vos données personnelles.</li>
                        <li>Droit de transférer vos données : vous avez le droit de demander toutes vos données personnelles au responsable du traitement et de les transférer dans leur intégralité à un autre responsable du traitement.</li>
                        <li>Droit d’opposition : vous pouvez vous opposer au traitement de vos données. Nous obtempérerons, à moins que certaines raisons ne justifient ce traitement.</li>
                    </ul>
                    <br>
                    <p>Pour exercer ces droits, veuillez nous contacter. Veuillez vous référer aux coordonnées au bas de cette politique de cookies. Si vous avez une plainte concernant la façon dont nous traitons vos données, nous aimerions en être informés, mais vous avez également le droit de déposer une plainte auprès de l’autorité de contrôle (l’autorité chargée de la protection des données, comme le CEPD).</p>
                    <!--  -->
                    <h3 class="my-5">Activer/désactiver et supprimer les cookies</h3>
                    <p>Vous pouvez utiliser votre navigateur internet pour supprimer automatiquement ou manuellement les cookies. Vous pouvez également spécifier que certains cookies ne peuvent pas être placés. Une autre option consiste à modifier les réglages de votre navigateur Internet afin que vous receviez un message à chaque fois qu’un cookie est placé. Pour plus d’informations sur ces options, reportez-vous aux instructions de la section Aide de votre navigateur.</p>
                    <p>Veuillez noter que notre application peut ne pas marcher correctement si tous les cookies ne sont pas acceptés. Si vous supprimez les cookies dans votre navigateur, ils seront de nouveau placés après votre consentement lorsque vous revisiterez nos sites web.</p>

                    <!--  -->
                    <h3 class="my-5">Coordonnées</h3>
                    <p>Pour des questions et/ou des commentaires sur notre politique de cookies et cette déclaration, veuillez nous contacter en utilisant les coordonnées suivantes :</p>
                    <p>
                        <b>Logipro</b><br/>
                        4 rue du PNDF 43000 Le Puy-en-Velay<br/>
                        France<br/>
                        Site web : <a href="https://app.stromeety.com">https://app.stromeety.com</a><br/>
                        E-mail : <a href="mailto:rgpd@stromeety.com">rgpd@stromeety.com</a><br/>
                        Numéro de téléphone: 04 71 09 15 53
                    </p>

                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Cookies générale -->
        <v-dialog
        v-model="alertCookie"
        :max-width="800"
        persistent
        >
            <v-card class="mx-auto">
                <v-card-text class="py-5" style="text-align:center;">
                    <v-icon center size="70" color="primary"> mdi-cookie </v-icon>
                    
                    <!--  -->
                    <h2 class="my-5">Stromeety respecte votre vie privée</h2>
                    <p>Pour améliorer votre expérience et avec votre accord, nous pouvons <b>enregistrer des informations</b> grâce à des cookies, sur le terminal de votre navigateur. <b>Aucune données personnelles</b> ne sont utilisées à des fins commerciales.</p>

                    <!--  -->
                    <h3 class="my-5">
                        Cookies d'optimisation
                    </h3>
                    <p>Ces cookies sont exclusivement utilisés pour permettre le <b>bon fonctionnement</b> de l'application et améliorer votre expérience utilisateur. Ils sont indispensables aux fonctionnalités essentielles du site, pour vous permettre notamment de garder votre session ouverte et de garantir la <b>sécurité de votre compte</b>.</p>

                    <!--  -->
                    <h3 class="my-5">
                        Cookies de publicités
                    </h3>
                    <p>Vos données personnelles saisies sur Stromeety ne <b>sont pas utilisées à des fins commerciales</b>.</p>
                    
                    <!--  -->
                    <h3 class="my-5">
                        Conservation
                    </h3>
                    <p>Nous conservons votre choix pendant <b>6 mois</b>. Vous pouvez changer d'avis à tout moment en cliquant sur l'icône <b>"gérer les cookies"</b> en bas de chaque page de Stromeety.</p>

                    Voir la <a @click="showFullCookies()">Politique de cookies</a>
                </v-card-text>
                <v-card-actions class="pb-5" style="text-align:center;">
                    <v-btn color="primary" width="40%" style="margin:0 3% 0 7%" @click="accept()" dark>
                        Accepter
                    </v-btn>
                    <v-btn color="primary" width="40%" style="margin:0 7% 0 3%" @click="reject()" dark>
                        Refuser
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'Cookie',
    data() {
        return {
            alertCookie: false,
            alertCookieDetails: false
        };
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            if (this.$cookies.get("stromeetyCookies")) {
                this.alertCookie = false
            }
            else {
                this.alertCookie = true
                this.removeAll();
            }
        },
        removeAll() {
            this.removeLocalStorage();
            this.removeCookies();
        },
        removeLocalStorage() {
            localStorage.clear();
        },
        removeCookies() {
            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
        },
        accept() {
            this.$cookies.set(
                "stromeetyCookies",
                true,
                60 * 60 * 24 * 180, // Secondes pour 6 mois
            );
            this.alertCookie = false
        },
        reject() {
            this.removeAll();
            this.$router.push({ name: 'login'});
        },
        showCookies() {
            this.alertCookie = true;
        },
        closeCookieDialog() {
            this.alertCookie = false;
        },
        showFullCookies() {
            this.alertCookie = false;
            this.alertCookieDetails = true;
        }
    }
}
</script>