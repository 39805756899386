<template>
    <div>
        <h1 class="text-center" v-if="titleState == false">{{ title }}</h1>
        <v-row justify="center">
          <v-col cols="11" class="pa-0">
            <Control @hideTitle="hideH1(status)" @changeTitle="changeTitle(roomTitle)"></Control>
          </v-col>
        </v-row>
    </div>
</template>

<script>
import Control from './room/Control'

export default {
    name: 'RoomPage',
    components: {
        Control
    },
    data: () => ({
      title: "",
      titleState: false
    }),
    methods: {
      changeTitle(roomTitle) {
        this.title = roomTitle
      },
      hideH1(status) {
        this.titleState = status
      }
    }
}
</script>
