<template>
  <div>
    <v-snackbar
     v-model="infoAlertControl"
     color="info"
     :top="top"
     :timeout="timeout"
     :right="right"
    >
      <v-icon dark right>mdi-close-circle</v-icon>
      {{ infoMessageControl }}
    </v-snackbar>
  </div>

</template>

<script>

export default {
  name: "InfoAlert",
  data: () => ({
    infoAlertControl: false,
    infoMessageControl: 'Info message',
    timeout: 5000,
    top: true,
    right: true
  }),
  methods: {
    popAlert (message) {
        this.infoMessageControl = message
        if (message.lenght == 0) {
          this.infoAlertControl = false;
        } else {
          this.infoAlertControl = true;
        }
    },
  }
};
</script>
