<template>
  <div>
    <v-snackbar
     v-model="successAlertControl"
     color="success"
     :top="top"
     :right="right"
     :timeout="timeout"
    >
      <v-icon dark right>mdi-check-circle</v-icon>
      {{ successMessageControl }}
    </v-snackbar>
  </div>
</template>

<script>

export default {
  name: "SuccessAlert",
  data: () => ({
    successAlertControl: false,
    successMessageControl: 'Success message',
    timeout: 5000,
    top: true,
    right: true
  }),
  methods: {
    popAlert (message) {
        // Exemple utilisation : this.$refs.SuccessAlert.popAlert('Exemple de pop message');
        this.successAlertControl = true;
        this.successMessageControl = message
    }
  }
};
</script>
