<template>
  <div>
    <div>
      <h2 style="text-align:center">Participants</h2>
      <v-divider class="ma-5"></v-divider>
      <v-row>
        <v-col lg="12">

        <SuccessAlert ref="SuccessAlert"></SuccessAlert>
        <ErrorAlert ref="ErrorAlert"></ErrorAlert>

        <v-alert
          dense
          text
          type="info"
          v-if="bloquePouvoir"
        >
          L'évènement est <strong>terminé</strong>, vous ne pouvez plus modifier les pouvoirs.
        </v-alert>

          <v-data-table
            :headers="headers"
            :items="participants"
            :items-per-page="5"
            sort-by="name"
            class="elevation-1"
            :disable-sort="$vuetify.breakpoint.xsOnly"
          >
            <template v-slot:[`item.fullname`]="{ item }" justify-center>
              {{ item.name }} {{ item.firstname }}
            </template>
            <template v-slot:[`item.canVote`]="{ item }" justify-center>
              <v-switch
                v-model="item.canVote"
                @change="edit(item.canVote, item, 'canVote')"
                :disabled="bloquePouvoir"
              ></v-switch>
            </template>
            <template v-slot:[`item.ponderation`]="{ item }">
              <v-text-field
                v-model="item.ponderation"
                type="number"
                min="10"
                max="100"
                step="10"
                outlined
                dense
                class="mt-5"
                @change="edit(item.ponderation, item, 'ponderation')"
                :disabled="bloquePouvoir"
              ></v-text-field>
            </template>
            <template v-slot:[`item.newMandataire`]="{ item }">
              <v-select
                  v-model="item.pouvoirList"
                  :items="participantsList"
                  :item-value="pouvoirList.id"
                  label=""
                  width=20
                  @change="edit(item.pouvoirList.id, item, 'mandataire', item.pouvoirList)"
                  :disabled="bloquePouvoir"
              >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }} {{ data.item.firstname }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.name }} {{ data.item.firstname }}
              </template>
              </v-select>
            </template>
            <template v-slot:[`item.pouvoir`]="{ item }" center>
              <span v-if="item.pouvoir">
                {{ item.pouvoir }} 
                <v-btn
                  icon
                  color="red"
                  @click="edit(null, item, 'deletePouvoir')"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </span>
              <span v-else>
                -
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ErrorAlert from "../alerts/Error.vue"
import SuccessAlert from "../alerts/Success.vue"

import Vue from 'vue';

  export default {
    name: 'Pouvoir',
    components: { 
      ErrorAlert,
      SuccessAlert
    },
    data: () => ({
      headers: [
        { text: 'Droit de vote', value: 'canVote', align: 'center', width: "10%" },
        { text: 'Nom / Prénom', value: 'fullname', align: 'center', width: "25%" },
        { text: 'Pondération', value: 'ponderation', align: 'center', width: "10%" },
        { text: 'Mandataire', value: 'pouvoir', align: 'center', width: "25%" },
        { text: 'Nouveau mandataire', value: 'newMandataire', align: 'center', width: "30%" },
      ],
      events: [],
      participants: [],
      participantsList: [],
      bloquePouvoir: false,
      fullname: "",
      editedIndex: [],
      editedItem: []
    }),

    created() {
      this.initialize()
    },
    methods: {
      initialize() {
        this.participants = [],
        this.participantsList = [],
        this.pouvoirList = ''
        axios({
          method: "get",
          url: this.apiUrl + "/evenements/participants/"+this.$route.params.id,
          headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
          }
        }).then(
          (response) => {
            this.participants = response.data
            this.participantsList = response.data
          },
          (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
      },
      edit(data, participant, type, item = null) {
        const editedIndex = this.participants.indexOf(participant)
        let tmp = [];

        if (type == "ponderation") {
          if (data < 10) {
            this.$refs.ErrorAlert.popAlert('Vous ne pouvez pas indiquer une pondération inférieure à 10');
          } else {
            tmp.push({ponderation: data});
          }
        } else if (type == "canVote") {
          tmp.push({canVote: data});
        } else if (type == "mandataire") {
          tmp.push({mandataire: data});
        } else if (type == "deletePouvoir") {
          tmp.push({mandataire: data});
        }
        axios({
          method: "patch",
          url: this.apiUrl + "/evenements/participants/"+participant.id,
          headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
          },
          data: tmp.shift()
        }).then(
          (response) => {
            if (type == "mandataire") {
              this.participants[editedIndex].pouvoir = item.name+" "+item.firstname
              Vue.set(this.participants, editedIndex, this.participants[editedIndex])
            } else if (type == "deletePouvoir") {
              let cpParticipant = this.participants[editedIndex]
              cpParticipant.pouvoir = null
              this.participants.splice(editedIndex, 1, cpParticipant)
            }
            this.$refs.SuccessAlert.popAlert(response.data.message);
          },
          (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
      },
      setStatus(status) {
        if (status == 0) {
          this.bloquePouvoir = true
        } else {
          this.bloquePouvoir = false
        }
      },
    },
  }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
