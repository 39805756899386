<template>
    <div>
        <h1>Membres</h1>
        <v-row justify="center">
          <v-col cols="12" md="11" sm="12">
            <Table></Table>    
          </v-col>
        </v-row>
    </div>
</template>

<script>
import Table from './members/Table'

export default {
    name: 'MemberPage',
    components: {
        Table
    }
}
</script>
