<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                        <v-text-field
                            v-model="eventCreate.name"
                            label="Nom"
                            maxlength="30"
                            counter
                            :rules="required"
                        ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                            <v-select
                                v-model="eventCreate.type"
                                :items="types"
                                :rules="required"
                                label="Type"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="6" sm="12">
                            <v-select
                                v-model="eventCreate.room"
                                :items="rooms"
                                item-text="name"
                                :rules="required"
                                label="Salon"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <!-- Selection date de l'event -->
                        <v-col cols="12" md="4" sm="6">
                            <v-menu
                                v-model="menuDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="computedDate"
                                        label="Date"
                                        append-icon="mdi-calendar"
                                        :rules="required"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @click:clear="date = null"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="eventCreate.date"
                                :min="getCurrentDate()"
                                @input="menuDate = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6" md="4" sm="6">
                            <!-- Selection heure de l'évènement -->
                            <v-text-field
                                v-model="eventCreate.time"
                                label="Heure"
                                :rules="required"
                                value="14:00"
                                type="time"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="4" sm="6">
                            <!-- Selection durée de l'event -->
                            <v-text-field
                                v-model="eventCreate.duration"
                                label="Durée"
                                :rules="required"
                                value="00:30"
                                type="time"
                                ref="duration"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                <v-file-input
                    v-model="eventCreate.fileBBB"
                    accept="image/png, image/jpeg, .pdf"
                    show-size
                    label="Image de présentation BBB (JPG, PNG, PDF)"
                    append-icon="mdi-paperclip"
                    prepend-icon=""
                    type="file"
                    ref="fileInput"
                ></v-file-input>
                </v-col>

                <v-col cols="12">
                <v-textarea
                    v-model="eventCreate.description"
                    rows=3
                    maxlength="200"
                    counter
                    name="input-7-1"
                    label="Description"
                ></v-textarea>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    class="mx-2"
                    @click="validate"
                >
                Suivant
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
// import axios from 'axios';

export default {
    name: 'Step1',
    props: {
        rooms: Array,
    },
    data: () => ({
      dialog: false,
      valid: true,
      e1: 1,
      types: ['Visio-conférence', 'Webinaire'],
      test: 'test',
      time: null,
      menuDate: false,
      menuTime: false,
      menuDuration: false,
      duration: '30',
      eventCreate: {
        name: null,
        type: null,
        room: null,
        date: null,
        time: '14:30',
        duration: '00:30',
        fileBBB: null,
        description: null,
        currentDate: null
      },
      DurationHours: [
        '15mn',
        '30mn',
        '45mn',
        '1h',
        '1h30',
        '2h',
        '2h30',
        '3h',
        '3h30',
        '4h',
        '4h30'
      ],
      required: [
        v => !!v || 'Champ requis',
      ],
    }),
  methods: {
        resetForm() {
          this.$refs.form.resetValidation()
          this.eventCreate.name = null
          this.eventCreate.type = null
          this.eventCreate.room = null,
          this.eventCreate.date = null,
          this.eventCreate.fileBBB = null
          this.eventCreate.description = null
          this.eventCreate.time = '14:30',
          this.eventCreate.duration = '00:30'
        },
        validate () {
            if(this.$refs.form.validate()) {
            this.$emit('changeEl', '2');
            this.$emit('stepOne', this.eventCreate);
            } 
        },
        checkLengthDate(option) {
            if (option < 10) {
                option = '0'+option;
                return option;
            } else {
                return option;
            }
        },
        getCurrentDate() {
            const actualDate = new Date();
            const actualDateFormat = actualDate.getFullYear()+'-'+this.checkLengthDate(actualDate.getMonth()+1)+'-'+this.checkLengthDate(actualDate.getDate());
            return actualDateFormat;
        }
    },
    computed: {
        computedDate () {
            const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"];
            var day = "";
            if (this.eventCreate.date != null) {
                var newDate = new Date(this.eventCreate.date);
                day = 'Le '+newDate.getDate()+' '+monthNames[newDate.getMonth()]+' '+newDate.getFullYear();
            }
            return day;
        }
    }
}
</script>
