<template>
  <div>
    <v-btn
    color="secondary"
    dark
    @click="exportPresences(eventId)"         
    >Exporter les présences</v-btn>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ExportPresences",
  props: {
    eventId: Number,
    eventName: String
  },
  methods: {
    exportPresences(eventId) {
      axios({
        method: "get",
        url: this.apiUrl + "/bbb/stats/"+eventId,
        headers: {
          "STROMEETY-AUTH-TOKEN": this.$globalData.token,
        },
        responseType: "blob",
      }).then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.eventName + "_presences.pdf");
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.globalErrorMethod(error);
        }
      );
    }
  },
};
</script>