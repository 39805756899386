<template>
    <div>
        <v-footer
            class="footer"
            padless
            v-if="!$route.path.includes('meeting')"
            >
            <v-row
            justify="center"
            no-gutters
            >
                <!-- Pas mobile -->
                <v-col
                    class="py-4 text-center link-footer"
                    cols="12"
                    v-if="!$vuetify.breakpoint.smAndDown"
                >
                    <a @click="showCookie()">{{ textCookies }}</a>
                    <span class="mx-2"></span>
                    <a @click="showMention()">{{ textMentionsLegales }}</a>
                    <span class="ml-5 mr-2"></span>
                    {{ copyright }}
                </v-col>
                <!-- Mobile -->
                <v-col
                    class="py-4 text-center link-footer"
                    cols="12"
                    v-else
                >
                    <v-row>
                        <v-col cols="6" class="text-right">
                            <a @click="showCookie()">{{ textCookies }}</a>
                        </v-col>
                        <v-col cols="6" class="text-left">
                            <a href="">{{ textMentionsLegales }}</a>
                        </v-col>
                        <v-col cols="12">
                            {{ copyright }}
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-footer>
        <Cookie ref="Cookie"></Cookie>
        <Mention ref="Mention"></Mention>
    </div>
</template>

<script>
import Cookie from './Cookie';
import Mention from './Mention';

export default {
    name: 'Footer',
    components: {
        Cookie,
        Mention
    },
    data() {
        return {
            textCookies: 'Gérer les cookies',
            textMentionsLegales: 'Mentions générales',
            copyright: 'Copyright © 2021 Stromeety'
        }
    },
    methods: {
        showCookie() {
            this.$refs.Cookie.showCookies();
        },
        showMention() {
            this.$refs.Mention.showMentions();
        }
    }
}
</script>