<template>
    <div>
        <div class="mb-5">
            <v-img src="@/assets/images/logo_full.png" alt="logo stromeety" :width="$vuetify.breakpoint.smAndDown ? '100' : '200'" class="mx-auto"></v-img>
        </div>
        <v-row justify="center">
          <v-col cols="11">
              <v-row v-if="events">
                  <v-col cols="12">
                      <Progress :events="events"></Progress>
                  </v-col>
                    <v-col cols="12" lg="6" fill-height>
                        <Events :events="events"></Events>
                    </v-col>
                <v-col cols="12" md="12" lg="6" fill-height>
                    <v-row>
                        <v-col>
                             <Historique :events="events"></Historique>
                            <Rooms></Rooms>
                        </v-col>
                    </v-row>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
        <HelpPage v-if="!$vuetify.breakpoint.smAndDown"></HelpPage>
    </div>
</template>

<script>
import Progress from './home/Progress'
import Events from './home/Events'
import Historique from './home/Historique'
import Rooms from './home/Rooms'

import HelpPage from './HelpPage'

import axios from "axios";

export default {
    name: 'HomePage',
    components: {
        Progress,
        Events,
        Historique,
        Rooms,
        HelpPage
    },
    data: () => ({
      events: null
    }),
    created () {
      this.initialize()
      console.log(this.apiUrl)
    },
    methods: {
        initialize () {
            axios.get(this.apiUrl + "/evenements/list", {
                headers: {
                    "STROMEETY-AUTH-TOKEN": this.$globalData.token,
                }
            }).then(
            (response) => {
                this.events = response.data
            },
            (error) => {
                this.globalErrorMethod(error)
            }
            );
        }
    }
}
</script>

<style lang="scss">

</style>