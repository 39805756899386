import { authHeader } from "../router/auth-header";

export const userService = {
  login,
  logout,
  getAll
};

function login(user) {
      if (user) {
        localStorage.setItem("user", JSON.stringify(this.user));
        // eslint-disable-next-line no-unused-vars
        user => this.$router.push(this.returnUrl);
      }
      return user;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
