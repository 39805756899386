var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('v-row',{staticClass:"d-flex flex-wrap mt-5 mb-10"},[(_vm.list.length == 0)?_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"lg":"12","md":"6","sm":"12"}},[_vm._v(" Vous ne participez dans aucun évènement dans ce salon. ")]):_vm._e(),_vm._l((_vm.list),function(event){return _c('v-col',{key:event.id,attrs:{"lg":"4","md":"6","sm":"12"}},[_c('v-card',{staticClass:"mt-4 mr-4",attrs:{"width":"100%"}},[_c('v-toolbar',{staticClass:"primary",attrs:{"dark":""}},[_c('v-card-title',[_vm._v(_vm._s(event.name))])],1),_c('v-card-text',[_c('div',{staticClass:"text--primary mb-5"},[_vm._v(" Le "+_vm._s(event.dateF)+", d'une durée de "+_vm._s(event.durationF)+" ")]),(event.description)?_c('p',[_vm._v(_vm._s(event.description))]):_c('p',[_vm._v("Aucune description")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","to":{ name: 'meeting', params: { id: event.id }, query: { method: 'open' }}}},[_vm._v(" "+_vm._s(_vm.$globalData.isAdmin == true ? 'Démarrer' : 'Rejoindre')+" ")])],1)],1)],1)})],2),_c('v-sheet',{staticClass:"pa-5",attrs:{"color":"white","rounded":"","shaped":""}},[_c('h2',[_vm._v("Historique des enregistrements")]),_c('v-divider',{staticClass:"my-5"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataRecords,"sort-by":"startTime","sort-desc":true,"locale":"fr","disable-sort":_vm.$vuetify.breakpoint.xsOnly},scopedSlots:_vm._u([{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertTime(item.startTime))+" ")]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertTime(item.endTime))+" ")]}},{key:"item.playbackUrl",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.playbackUrl,"target":"_blank"}},[_vm._v(" Voir la rediffusion ")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state === 'published')?_c('div',[_vm._v(" Publié ")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }