<template>
  <div>
    <h2 class="mb-5">Liste des participants ({{ totalParticipants }})</h2>
    <ErrorAlert ref="ErrorAlert"></ErrorAlert>
    <v-data-table
      v-model="dataParticipant"
      :headers="(eventData.status == 0 || $globalData.isAdmin != true) ? headers : headersSelect"
      :items="participants"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20]
      }"
      :items-per-page="5"
      checkbox-color="secondary"
      :show-select="eventData.status != 0 && $globalData.isAdmin == true"
      :disable-sort="$vuetify.breakpoint.xsOnly"
    >
      <v-data-table-header
      checkbox-color
      ></v-data-table-header>
      <template v-slot:[`item.fullname`]="{ item }">
        {{ item.name }} {{ item.firstname }}
      </template>
    </v-data-table>
    <v-btn color="secondary" v-if="$globalData.isAdmin == true" @click="sendMails" :disabled="eventData.status == 0 || numberSelected == 0">Envoyer un mail à {{ numberSelected }} participants</v-btn>


  </div>
</template>

<script>
import axios from "axios";
import ErrorAlert from "../../alerts/Error.vue";

export default {
  name: "Participant",
  components: { 
      ErrorAlert
  },
  props: ["eventData", "bus"],
  data() {
    return {
      headersSelect: [
        { text: "", sortable: false, value: "data-table-select", class: 'headerColor' },
        {
          text: "Nom / Prénom",
          align: "center",
          value: "fullname",
          class:'primary white--text'
        },
      ],
      headers: [
        {
          text: "Nom / Prénom",
          align: "center",
          value: "fullname",
          class:'primary white--text'
        },
      ],
      dataParticipant: [],
      participants: [],
      totalParticipants: 0,
    };
  },
  created() {
    this.listParticipants(this.eventData.id);
  },
  computed: {
      numberSelected: function() {
        return this.dataParticipant.length
      },
  },
  methods: {
    listParticipants(eventId) {
        this.dataParticipant = [];
        axios({
          method: "get",
          url: this.apiUrl + "/evenements/participants/"+eventId,
          headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
          }
        }).then(
          (response) => {
            this.participants = response.data
            this.totalParticipants = this.participants.length;
          },
          (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
    },
    sendMails() {
      axios({
        method: "post",
        url: this.apiUrl + "/evenements/mails/"+this.eventData.id,
        headers: {
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        },
        data: {
          data: this.dataParticipant,
          all: false
        },
      }).then(
        (response) => {
          this.dataParticipant = [],
          this.$emit('successAlertF', response.data);
        },
        (error) => {
          this.globalErrorMethod(error)
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
        }
      );
    }
  },
};
</script>