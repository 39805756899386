
import VueRouter from 'vue-router';
// Pages
import HomePage from '../components/HomePage';
import EventPage from '../components/EventPage';
import RoomsPage from '../components/RoomsPage';
import RoomPage from '../components/RoomPage';
import VotesPage from '../components/VotesPage';
import VotePage from '../components/VotePage';
import MemberPage from '../components/MemberPage';
import SettingPage from '../components/SettingPage';
import MeetingPage from '../components/MeetingPage';
import LoginPage from '../components/LoginPage';
import ValidationPage from '../components/ValidationPage';
import NotFoundPage from '../components/NotFoundPage';
import PasswordResetPage from '../components/PasswordResetPage';

const authGuard = (to,from,next) => {
  const loggedIn = localStorage.getItem('user');

  if (!loggedIn) {
    return next({ 
      path: '/login', 
      query: { returnUrl: to.path } 
    });
  }

    next();
}

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes: [
      { path: '/', component: HomePage, name: 'home', beforeEnter: authGuard },
      { path: '/evenements', component: EventPage, name: 'events', beforeEnter: authGuard },
      { path: '/votes', component: VotesPage, name: 'votes', beforeEnter: authGuard },
      { path: '/vote/:id', component: VotePage, name: 'vote', beforeEnter: authGuard },
      { path: '/salons', component: RoomsPage, name: 'rooms', beforeEnter: authGuard },
      { path: '/salon/:id', component: RoomPage, name: 'room', beforeEnter: authGuard },
      { path: '/membres', component: MemberPage, name: 'members', beforeEnter: authGuard },
      { path: '/parametres', component: SettingPage, name: 'setting', beforeEnter: authGuard },
      { path: '/meeting/:id', component: MeetingPage, name: 'meeting', beforeEnter: authGuard },
      { path: '/login', component: LoginPage, name: 'login' },
      { path: '/password', component: PasswordResetPage, name: 'password_reset' },
      { path: '/confirmation/:code', component: ValidationPage, name: 'confirmation' },
      { path: '*', component: NotFoundPage, name: '404' },
    ],
  });


