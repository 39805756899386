<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-img
              src="@/assets/images/logo_full.png"
              class="mb-10"
              height="200"
              contain
            ></v-img>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title class="mx-3">Réinitialiser mon mot de passe</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <ErrorAlert ref="ErrorAlert"></ErrorAlert>
                <v-form @submit.prevent="submitChangePassword">
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="email"
                      prepend-icon="mdi-account"
                      name="email"
                      label="Email *"
                      type="text"
                      :disabled="true"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="newPassword"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Nouveau mot de passe"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      @click:append="show2 = !show2"
                      autocomplete="new-password"
                      counter
                    ></v-text-field>
                    <v-text-field
                      v-model="newPasswordConfirm"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Confirmation du nouveau mot de passe"
                      :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show3 ? 'text' : 'password'"
                      @click:append="show3 = !show3"
                      autocomplete="new-password"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-card-actions>
                    <div v-if="error" class="alert alert-danger">
                      {{ error }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" type="submit"
                      >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import ErrorAlert from "./alerts/Error.vue"

export default {
    name: 'PasswordResetPage',
    components: { 
      ErrorAlert
    },
    data () {
        return {
            show2: false,
            show3: false,
            error: false,
            email: "",
            newPassword: "",
            newPasswordConfirm: ""
        }
    },
    created () {
      this.initialize()
    },
    methods: {
      initialize () {
        axios.get(this.apiUrl+"/membres/"+this.$route.query.token, {
          headers: {
            'STROMEETY-AUTH-TOKEN': this.$route.query.token
          }
        })
        .then((response) => {
          this.email = response.data
        }, (error) => {
          this.globalErrorMethod(error)
        });
      },
      submitChangePassword() {
        if ((this.newPassword || this.newPasswordConfirm) && (this.newPassword == this.newPasswordConfirm)) {
          axios({
            method: 'put',
            url: this.apiUrl+'/parametres/password',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'STROMEETY-AUTH-TOKEN': this.$route.query.token
            },
            data: {
              password: this.newPassword,
              rePassword: this.newPasswordConfirm,
              token: this.$route.query.token
            }
          })
          .then((response) => {
            this.$router.push('/login?success='+response.data)
          }, (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          });
        } else {
            this.$refs.ErrorAlert.popAlert('Vos mots de passe sont vides ou ne correspondent pas.');
        }
      }
    }
}
</script>