<template>
  <div>
    <ErrorAlert ref="ErrorAlert"></ErrorAlert>
    <span v-if="!voteExists">Aucun vote pour cet évènement.</span>
    <div v-else>
      <download-excel :data="exportXls.data" type="xls">
        <v-btn class="mx-2" :style="$route.name == 'events' ? 'float:left !important' : 'float:right !important'" fab dark small color="#1D6F42">
          <v-icon dark> mdi-microsoft-excel </v-icon>
        </v-btn>
      </download-excel>
      <v-btn
        class="mx-2"
        :style="$route.name == 'events' ? 'float:left !important' : 'float:right !important'"
        fab
        small
        dark
        color="#F40F02"
        @click="getInfoPdf()"
      >
        <v-icon dark> mdi-file-pdf-box </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ErrorAlert from "../alerts/Error.vue"

export default {
  name: "DownloadVote",
  components: { 
      ErrorAlert
  },
  props: ["eventData"],
  data: () => ({
    exportXls: [],
    eventName: null,
    idEvent: null,
    voteExists: false
  }),
  created() {
    this.setEventId(this.eventData.id);
    this.eventName = this.eventData.name
  },
  methods: {
    setEventId(idEvent) {
        this.idEvent = idEvent
        this.getExportXls();
    },
    getExportXls() {
      axios({
        method: "get",
        url: this.apiUrl + "/vote/export/xls",
        headers: {
          "STROMEETY-AUTH-TOKEN": this.$globalData.token,
        },
        params: {
          eventId: this.idEvent,
        },
      }).then(
        (response) => {
          this.exportXls = response.data;
          this.checkIfVotes(response.data)
        },
        (error) => {
          this.globalErrorMethod(error);
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
        }
      );
    },
    checkIfVotes(data) {
      if(data.data.length > 0) {
        this.voteExists = true
      } else {
        this.voteExists = false
      }
    },
    getInfoPdf() {
      axios({
        method: "get",
        url: this.apiUrl + "/vote/export/pdf",
        headers: {
          "STROMEETY-AUTH-TOKEN": this.$globalData.token,
        },
        params: {
          eventId: this.idEvent,
        },
        responseType: "blob",
      }).then(
        (response) => {
          this.pdf = response.data;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.eventName + "_votes.pdf");
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.globalErrorMethod(error);
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
        }
      );
    },
  },
};
</script>

