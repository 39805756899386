<template>
    <div>
        <h1 class="text-center">Mes évènements</h1>
        <v-row justify="center">
          <v-col cols="11">
            <Table></Table>    
          </v-col>
        </v-row>
    </div>
</template>

<script>
import Table from './votes/Table'

export default {
    name: 'VotesPage',
    components: {
        Table
    }
}
</script>
