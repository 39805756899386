<template>
  <div>
    <InfoAlert ref="InfoAlert"></InfoAlert>
    <ErrorAlert ref="ErrorAlert"></ErrorAlert>
    <v-dialog
      v-model="voteDialog"
      :persistent="!resultatsActive"
      transition="dialog-top-transition"
      :max-width="resultatsActive ? '400' : '600'"
    >
      <v-card>
        <v-toolbar
            color="primary"
            class="subtitle-1 pl-5"
            dark
        >{{ resultatsActive ? 'Voir les résultats' : 'Nouvelle question' }}</v-toolbar>
          <v-progress-linear
            background-color="secondary"
            color="primary"
            reverse
            height="5"
            :value="countdownBarre"
            v-if="resultatsActive == false"
          ></v-progress-linear>
          
        <v-card-text>

          <v-alert
            type="info"
            border="left"
            v-model="infoAlertControl"
            class="my-5"
            transition="slide-y-transition"
            :value="infoAlertControl"
            v-if="resultatsActive == false"
          >
            {{ infoMessageControl }}
          </v-alert>

          <div v-if="resultatsActive == false">
            <div class="text-right my-3">
              <span>{{ countdown }} secondes </span>
            </div>
            <div class="text-h6">
              {{voteResponse.question}}
            </div>
            <div class="my-3">
                Vous votez en tant que : <b>{{ userVotant.fullname }}</b>
            </div>
            <div>
              <v-radio-group v-model="voteChoice">
                <v-radio
                  v-for="item in voteChoices"
                  :disabled="countdown == 0"
                  :key="item"
                  :label="item"
                  :value="item"
                ></v-radio>
              </v-radio-group>
            </div>
            <div v-if="voteResponse.anonymeF == true">
              <div class="text-caption">Vote anonyme*</div>
            </div>
          </div>
        <!-- Resultats -->
          <div v-else>
            <v-select
              v-model="votesFinishModel"
              :items="votesFinish"
              item-text="question"
              item-value="id"
              label="Question"
              @change="loadResultats(votesFinishModel)"
            >
            </v-select>

            <div class="my-2" v-if="resultats.nbVotes > 0">Taux de participation de {{ resultats.pourcentage }}% ({{ resultats.nbVotes }}/{{ resultats.nbParticipants }})</div>
            <div class="my-2" v-else>Aucun vote enregistré </div>
            <v-progress-linear
              v-for="item in resultats.votes"
              :value="item.pourcentage"
              :key="item.answer"
              rounded
              light
              class="mb-5"
              color="secondary"
              height="20"
            >
              <strong dark>{{ item.answer }} : {{ item.pourcentage }} ({{ item.nb }})</strong>
            </v-progress-linear>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end" v-if="resultatsActive == false">
            <v-btn
            color="secondary"
            dark
            @click="submitVote(voteActif)"
            :disabled="countdown == 0"            
            >Voter</v-btn>
        </v-card-actions>
        <v-card-actions class="justify-start" v-else>
            <v-btn
            color="secondary"
            dark
            @click="closeVoteDialog()"           
            >Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Résultats -->
    <v-dialog
    v-model="resultsDialog"
    persistent
    transition="dialog-top-transition"
    max-width="400"
    >

      <v-card>
        <v-toolbar
            color="primary"
            class="text-h6"
            dark
        >{{ voteResponse.question }}</v-toolbar>
          
        <v-card-text>
          <v-select
            v-model="votesModel"
            :items="votes"
            item-text="title"
            item-value="id"
            label="Ma question"
            item-disabled="status"
          >
          </v-select>
        </v-card-text>
        <v-card-actions class="justify-end" v-if="resultatsActive == false">
            <v-btn
            color="secondary"
            dark
            @click="submitVote(voteActif)"
            :disabled="countdown == 0"            
            >Voter</v-btn>
        </v-card-actions>
        <v-card-actions class="justify-start" v-else>
            <v-btn
            color="secondary"
            dark
            @click="closeVoteDialog()"           
            >Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import axios from "axios";
import InfoAlert from "../alerts/Info.vue"
import ErrorAlert from "../alerts/Error.vue"

export default {
  name: "Vote",
  components: { 
    InfoAlert,
    ErrorAlert
  },
  data() {
    return {
      voteDialog: false,
      countdownBarre: 0,
      countdownBarreRatio: null,
      countdown: null,
      voteChoices: ['Oui', 'Non', 'S\'abstient'],
      voteChoice: null,
      voteResponse: {
        question: '',
        time: '',
        anonyme: ''
      },
      resultatsActive: false,
      resultats: [],
      resultsDialog: false,
      votesFinish: [],
      votesFinishModel: '',
      votes: null,
      votesModel: '',
      infoAlertControl: false,
      infoMessageControl: "",
      timer: null,
      disabledVotes: false,
      usersVote: [],
      userVotant: [],
      indexVotant: 0,
      voteActif: null
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.voteExist();
    },
    submitVote(id) {
      axios({
        method: "post",
        url: this.apiUrl + "/vote/"+ id +"/submit",
        headers: {
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        },
        data: {
          participantId: this.usersVote[this.indexVotant].id,
          voteChoice: this.voteChoice
        },
      }).then(
        () => {
          if (this.usersVote[this.indexVotant+1] != null) {
            this.indexVotant = this.indexVotant+1
            this.userVotant = this.usersVote[this.indexVotant]
            this.voteChoice = null
            this.questionActive = true
            this.adminVote = false
            this.searchVote = false
          } else {
            this.indexVotant = 0;
            this.questionActive = false
            this.votesFinishModel = this.voteResponse.question
            this.showResultsFromBtn()
          }
        },
        (error) => {
          this.globalErrorMethod(error)
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
          if (error.response.data.code == 'TOO_LATE') {
            this.$emit('getList');
            this.questionActive = false
            this.adminVote = true
          }
        }
      );
    },
    closeVoteDialog() {
      this.voteDialog = false;
    },
    stockResults(data) {
      this.resultats = data
    },
    showResultsFromBtn(votes) {
      this.$emit('getList');
      this.votes = votes
      this.infoAlertControl = false
      this.voteDialog = true;
      this.resultatsActive = true
    },
    getListVotes(data) {
      this.votes = data
    },
    sortVotes(data) {
      data.forEach((value) => {
        if (value.status == 1 || value.status == 2) {
          this.votesFinish.push(value);
        }
      });
    },
    loadResultats(id) {
      if(id == null) {
        this.resultats.nbVotes = 0
      }
      else {
        axios({
          method: "get",
          url: this.apiUrl + "/vote/resultats",
          headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
          },
          params: {
            voteId: id,
          },
        }).then(
          (response) => {
            
            this.stockResults(response.data.data)
          },
          (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
      }
    },
 
    loadVote(id) {
      axios({
        method: "get",
        url: this.apiUrl + "/vote/"+id,
        headers: {
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        }
      }).then(
        (response) => {
          this.showVoteAfterLoading(response);
        },
        (error) => {
            this.globalErrorMethod(error);
            this.errorVoteAfterLoading(error.response.data);
        }
      )
    },
    voteExist() {
      axios({
        method: "get",
        url: this.apiUrl + "/vote/exist/"+this.$route.params.id,
        headers: {
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        },
      }).then(
        (response) => {
          this.infoAlertControl = false
          this.showVoteAfterLoading(response)
        },
        (error) => {
          this.globalErrorMethod(error)
          this.errorVoteAfterLoading(error.response.data);
        }
      )
    },
    showVoteAfterLoading(response) {
      if (response.data.code == 'OK') {
        this.infoAlertControl = false

        // On reset le timer
        this.countdownBarre = 0
        clearTimeout(this.timer);

        // Liste des utilisateurs si y a du pouvoir
        this.usersVote = response.data.users;

        this.voteDialog = true
        this.resultatsActive = false

        // On ajoute les datas du vote actuel
        this.voteActif = response.data.vote.id
        this.voteResponse = response.data.vote
        this.countdown = response.data.vote['time']
        this.$emit('actualVote', response.data.vote.id)
        this.countdownBarreRatio = 100 / response.data.vote['time']
        this.eventId = response.data.vote.id
        this.userVotant = response.data.users[0]

        this.countDownTimer();
        this.questionActive = true
        this.adminVote = false
        this.voteChoice = null
        this.$emit('changeEtat', 1);
      } else if (response.data.code == 'NO_VOTE') {
        this.resultatsActive = false
        this.$emit('changeEtat', '0');
      }
    },
    errorVoteAfterLoading(error) {
      this.$refs.InfoAlert.popAlert(error.message);
      if (error.code == 'HAVE_MANDATAIRE') {
        this.resultatsActive = false
        this.$emit('changeEtat', 1);
      } else if (error.code == 'ALREADY_VOTE' || error.code == 'CANNOT_VOTE') {
        this.showResultsFromBtn()
      }
    },
    countDownTimer() {
      if(this.countdown > 0) {
        this.timer = setTimeout(() => {
            this.countdown -= 1
            this.countdownBarre = this.countdownBarre + this.countdownBarreRatio
            this.countDownTimer()
        }, 1000)
      } else if(this.countdown == 0) {
        clearTimeout();
        this.questionActive = false
        this.voteDialog = false
      }
    },
  },
};
</script>

<style lang="scss">
  iframe{
    height: calc(100% - 70px)!important;
  }

  @media (max-width: 568px) {
    .v-btn{
      font-size: 0.6rem!important;
      margin: 4px 3px!important;
    }

    iframe{
      height: calc(100% - 160px)!important;
    }

    .flex-center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>