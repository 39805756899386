<template>
  <div>
    <v-card>
      <v-toolbar
          color="primary"
          dark
      >
          <v-card-title>
              <span class="headline">Mes salons</span>
          </v-card-title>
      </v-toolbar>
      <v-card-text class="text-h5" v-if="noRoom">
          Vous n'avez pas encore de salon
      </v-card-text>
      <v-card-text class="pb-0" v-if="!noRoom">
          <v-list class="py-0 overflow-y-auto" max-height="150px">
              <v-list-item-group
                  color="primary"
              >
                  <v-list-item
                      v-for="(item, i) in rooms"
                      :key="i"
                      :to="{ name: 'room', params: { id: item.id }}"
                      class="pa-0 px-3"
                  >
                      <v-list-item-content class="py-0">
                          <v-list-item-title>
                            {{ item.name }}
                            <v-chip
                              small
                              class="ma-2 font-weight-black"
                              color="secondary"
                              text-color="white"
                              v-if="item.nbEvents > 0"
                            >
                                {{ item.nbEvents }}
                            </v-chip>
                          </v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
          </v-list>
      </v-card-text>
      <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              type="submit"
              link
              :to="'/salons'"
          >
              Voir 
          </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

  export default {
    name: 'Rooms',
    data: () => ({
      noRoom: false,
      rooms: []
    }),
    created () {
      this.initialize()
    },
    methods: {
      initialize () {
          axios.get(this.apiUrl + "/rooms", {
              headers: {
                  "STROMEETY-AUTH-TOKEN": this.$globalData.token,
              },
              params: {
                  token: this.$globalData.token
              }
          })
          .then((response) => (
              this.rooms = response.data
          ));
      },
    }
  }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
