<template>
    <v-card>
            <p>Souhaitez-vous envoyer un mail d'invitation à tous les participants ?</p>

            <v-row>
                <v-col 
                    cols="12" 
                    md="6">
                    <v-select
                    :items="modeles"
                    value="Basic"
                    label="Modèles"
                    ></v-select>
                </v-col>
                <v-col 
                    cols="12" 
                    md="6">
                    <v-file-input
                        v-model="mailPj"
                        show-size
                        accept="image/png, image/jpeg, .pdf"
                        label="Pièce jointe (JPG, PNG, PDF)"
                        append-icon="mdi-paperclip"
                        ref="file"
                        prepend-icon=""
                        type="file"
                    ></v-file-input>
                </v-col>
            </v-row>

            <v-switch
            v-model="confirm"
            label="Confirmer l'envoi de mails."
            @click='changeT(confirm)'
            ></v-switch>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="previous" text> Retour </v-btn>
                <v-btn color="secondary" @click="validate" :loading="avoidButton">
                    <span v-if="sendMail == true">Terminer et envoyer</span>
                    <span v-if="sendMail == false">Terminer</span>
                </v-btn>
            </v-card-actions>
    </v-card>
</template>

<script>

export default ({
    name: 'Step3',
    data: () => ({
        confirm: false,
        modeles: ['Basic'],
        validBtn: null,
        sendMail: false,
        mailPj: null,
        avoidButton: false
    }),
    methods:{
        resetForm() {
        this.$refs.file.reset(),
        this.confirm = false,
        this.validBtn = false,
        this.sendMail = false
        },
        changeT (sendMail) {
            this.sendMail = sendMail;
        },
        previous () {
            this.$emit('changeEl', '2');   
        },
        validate () {
            const data = [];
            data.push({ sendEmail: this.sendMail, mailPj: this.mailPj });
            this.$emit('stepThree', data);
        },
        validButton(etat) {
            this.avoidButton = etat
        }
    }
})
</script>
