<template>
  <div>
    <SuccessAlert ref="SuccessAlert"></SuccessAlert>
          <!-- Dialog create user -->
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Créer un membre
              </v-btn>
            </template>
            <v-card>
              <v-form
                ref="form"
                @submit.prevent="save"
                lazy-validation
              >
                <v-card-title class="justify-center">
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <ErrorAlert ref="ErrorAlert"></ErrorAlert>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.name"
                          :rules="rules.required"
                          label="Nom"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.firstname"
                          :rules="rules.required"
                          label="Prénom"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.email"
                          :rules="emailRules"
                          :disabled="editedIndex > -1"
                          label="Email"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" v-if="editedIndex > -1">
                        <v-text-field
                          v-model="editedItem.phone"
                          label="Téléphone"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" v-if="editedIndex > -1">
                        <v-text-field
                          v-model="editedItem.city"
                          label="Ville"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" v-if="editedIndex > -1">
                        <v-checkbox
                          v-model="editedItem.isAdmin"
                          label="Administrateur"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="12" v-if="editedIndex === -1">
                        <span class="text-small">Un mail de confirmation sera envoyé à l'utilisateur pour confirmer son inscription et définir son mot de passe.</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions class="d-flex justify-center">
                    <v-btn
                      color="secondary"
                      text
                      @click="close"
                    >
                      Retour
                    </v-btn>
                    <v-btn
                      color="secondary"
                      type="submit"
                    >
                      {{ formButton }}
                    </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <!-- Dialog change password -->
          <v-dialog
            v-model="dialogPassword"
            max-width="500px"
          >
            <v-card>
              <v-form
                ref="form"
                @submit.prevent="savePassword"
                lazy-validation
              >
                <v-card-title class="justify-center">
                  <span class="text-h5">Modification du mot de passe</span>
                </v-card-title>

                <v-card-text>
                  <ErrorAlert ref="ErrorAlert"></ErrorAlert>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Mot de passe"
                        hint="8 caractères minimum"
                        autocomplete="new-password"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="rePassword"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min, passwordConfirmationRule]"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Répéter mot de passe"
                        hint="8 caractères minimum"
                        autocomplete="new-password"
                        counter
                        @click:append="show2 = !show2"
                      ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions class="d-flex justify-center">
                    <v-btn
                      color="secondary"
                      text
                      @click="closePassword"
                    >
                      Retour
                    </v-btn>
                    <v-btn
                      color="secondary"
                      type="submit"
                    >
                      Modifier
                    </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <!-- Dialog Delete -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 text-center">Êtes-vous sûr de vouloir désactiver le compte de {{ editedItem.name }} {{ editedItem.firstname }} ?</v-card-title>
              <v-card-actions class="d-flex justify-center">
                <v-btn color="secondary" text @click="closeDelete">Retour</v-btn>
                <v-btn color="secondary" @click="deleteItemConfirm">Désactiver</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      <v-sheet color="white"
      elevation="4"
      rounded
      shaped
      class="pa-4 mb-4">
    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
        align="center"
        style="max-width:500px; width:100%;"
        class="mb-5"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="membres"
      sort-by="name"
      locale="fr"
      :search="search"
      class="overflow-y-auto "
      v-if="!$vuetify.breakpoint.smAndDown"
    >
        <template v-slot:[`item.fullname`]="{ item }">{{ item.name }} {{ item.firstname }}</template>

        <template v-slot:[`item.etat`]="{ item }">
          <v-chip
            :color="getColor(item.etat)"
            dark
          >
            {{ getNameEtat(item.etat) }}
          </v-chip>
        </template>

        <template v-slot:[`item.isAdmin`]="{ item }">
            {{ getNameAdmin(item.isAdmin) }}
        </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="editPassword(item)"
        >
          mdi-lock
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="secondary"
          @click="initialize"
        >
          Réinitialiser
        </v-btn>
      </template>
    </v-data-table>
</v-sheet>
    <!-- Mobile version -->
    <div style="overflow-y: auto; height:90%;" v-if="$vuetify.breakpoint.smAndDown">

      <v-data-iterator
        :items="membres"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        class="mb-15"
        hide-default-footer
      >
      <template v-slot:item="props">
        <v-card 
          class="my-5 mr-2"
          >
          <v-toolbar class="primary" dark>
            <v-card-title>{{props.item.name}} {{ props.item.firstname }}</v-card-title>
          </v-toolbar>
          <v-card-text>{{props.item.email}}<br>{{props.item.phone}}</v-card-text>
          <v-card-actions>
              <v-chip
                :color="getColor(props.item.etat)"
                dark
              >
                {{ getNameEtat(props.item.etat) }}
              </v-chip>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2"
              @click="editItem(props.item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              class="mr-2"
              @click="editPassword(props.item)"
            >
              mdi-lock
            </v-icon>
            <v-icon
              @click="deleteItem(props.item)"
            >
              mdi-delete
            </v-icon>
          </v-card-actions>
        </v-card>
      </template>
      <template v-slot:footer>
        <v-row
          class="my-3"
          align="center"
          justify="center"
          style="position:absolute; bottom:0; width:100%;"
        >
          <span
            class="mr-4
            grey--text"
          >
            Page {{ page }} sur {{ numberOfPages }}
          </span>
          <v-btn
            fab
            small
            color="secondary"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            color="secondary"
            class="ml-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
      </v-data-iterator>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SuccessAlert from "../alerts/Success.vue"
import ErrorAlert from "../alerts/Error.vue"

  export default {
    name: 'Table',
    components: { 
      SuccessAlert, 
      ErrorAlert
    },
    data: () => ({
      search: '',
      dialog: false,
      dialogDelete: false,
      dialogPassword: false,
      password: '',
      rePassword: '',
      sortBy: "name",
      show1: false,
      show2: false,
      itemsPerPageArray: [4, 8, 12],
      page: 1,
      itemsPerPage: 10,
      headers: [
        { text: 'Etat', value: 'etat' },
        { text: 'Admin', value: 'isAdmin' },
        { text: 'Nom / Prénom', value: 'fullname' },
        { text: 'Email', value: 'email' },
        { text: 'Téléphone', value: 'phone' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      emailRules: [
        value => !!value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Email invalide.',
      ],
      rules: {
        required: value => !!value || 'Requis.',
        min: v => v.length >= 8 || 'Min 8 caractères',
      },
      membres: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        firstname: '',
        email: '',
        phone: '',
        city: '',
        isAdmin: false,
      },
      defaultItem: {
        name: '',
        firstname: '',
        email: '',
        phone: '',
        city: '',
        isAdmin: false,
      },
    }),

    computed: {
      passwordConfirmationRule() {
          return () => (this.password === this.rePassword) || 'Les mots de passe ne correspondent pas.'
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Créer un membre' : 'Modifier'
      },
      formButton() {
        return this.editedIndex === -1 ? 'Créer' : 'Modifier'
      },
      numberOfPages () {
        return Math.ceil(this.membres.length / this.itemsPerPage)
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        axios
        .get(this.apiUrl+'/membres', {
            headers: {
                'STROMEETY-AUTH-TOKEN': this.$globalData.token
            },
        })
        .then((response) => {
          this.membres = response.data
        }, (error) => {
          this.globalErrorMethod(error)
        });
      },

      editItem (item) {
        this.editedIndex = this.membres.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.membres.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      editPassword (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogPassword = true;
      },

      savePassword () {
        axios({
          method: 'put',
          url: this.apiUrl+'/parametres/password',
          headers: {
              'STROMEETY-AUTH-TOKEN': this.$globalData.token
          },
          data: {
            userMail: this.editedItem.email,
            password: this.password,
            rePassword: this.rePassword
          }
        })
        .then((response) => {
          this.$refs.SuccessAlert.popAlert(response.data);
          this.dialogPassword = false;
        }, (error) => {
          this.globalErrorMethod(error)
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
        });
      },

      deleteItemConfirm () {
        axios({
          method: 'delete',
          url: this.apiUrl+'/membres',
          headers: {
              'STROMEETY-AUTH-TOKEN': this.$globalData.token
          },
          data: {
            email: this.editedItem.email
          }
        })
        .then((response) => {
          this.membres.splice(this.editedIndex, 1)
          this.closeDelete()
          this.$refs.SuccessAlert.popAlert(response.data);
        }, (error) => {
          this.globalErrorMethod(error)
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
        });
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closePassword () {
        this.dialogPassword = false;
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        // Si form Edit
        if (this.editedIndex > -1) {
          axios({
            method: 'put',
            url: this.apiUrl+'/membres',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'STROMEETY-AUTH-TOKEN': this.$globalData.token
            },
            data: {
              name: this.editedItem.name,
              firstname: this.editedItem.firstname,
              email: this.editedItem.email,
              phone: this.editedItem.phone,
              city: this.editedItem.city,
              isAdmin: this.editedItem.isAdmin
            }
          })
          .then((response) => {
            this.$refs.SuccessAlert.popAlert(response.data);
            Object.assign(this.membres[this.editedIndex], this.editedItem)
            this.close()
            this.$refs.form.reset()
          }, (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          });

        } else { // Si form Create
          axios({
            method: 'post',
            url: this.apiUrl+'/membres',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'STROMEETY-AUTH-TOKEN': this.$globalData.token
            },
            data: {
              name: this.editedItem.name,
              firstname: this.editedItem.firstname,
              email: this.editedItem.email
            }
          })
          .then((response) => {
            this.$refs.SuccessAlert.popAlert(response.data);
            this.membres.push(this.editedItem)
            this.close()
          }, (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          });
        }
      },

      getColor (etat) {
        if (etat == 1) return 'green'
        else if (etat == 0) return 'orange'
        else return 'orange'
      },

      getNameEtat (etat) {
        if (etat == 1) return 'Vérifié'
        else if (etat == 0) return 'En attente'
        else return 'En attente'        
      },

      getNameAdmin (isAdmin) {
        if (isAdmin == true) return 'Administrateur'
        else return '-'        
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      }
    }
  }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
