<template>
    <div>
        <!-- Cookies détail -->
        <v-dialog
        v-model="alertMention"
        :max-width="1200"
        >
            <v-card class="mx-auto">
                <v-card-text class="py-5 cookie-details" style="text-align:center;">
                    <h2>Mentions Légales</h2>

                    <br>
                    <!--  -->
                    <h3 class="my-5">INFORMATIONS ÉDITEUR</h3>
                        <p class="text-center"><b>Stromeety</b></p>
                        <p class="text-center">
                            Cité numérique du Pensio<br>
                            4, rue du Pensio<br>
                            43000 Le Puy en Velay<br>
                            Tél. : 04 71 09 15 53<br>
                            <a hrep="www.stromeety.com">www.stromeety.com</a>
                        </p>

                    <!--  -->
                    <h3 class="my-5">CONCEPTION DU SITE</h3>
                        <p class="text-center"><b>Equipe de développement Stromeety</b></p>
                        <p class="text-center">
                            Cité numérique du Pensio<br>
                            4, rue du Pensio<br>
                            43000 Le Puy en Velay<br>
                            Tél. : 04 71 09 15 53<br>
                            <a href="www.logipro.com">www.logipro.com</a>
                        </p>

                    <!--  -->
                    <h3 class="my-5">HÉBERGEMENT DU SITE</h3>
                        <p class="text-center"><b>Logipro</b></p>
                        <p class="text-center">
                            Cité numérique du Pensio<br>
                            4, rue du Pensio<br>
                            43000 Le Puy en Velay<br>
                            Tél. : 04 71 09 15 53<br>
                            <a href="www.logipro.com">www.logipro.com</a>
                        </p>

                    <!--  -->
                    <h3 class="my-5">CONDITIONS GÉNÉRALES D’UTILISATION DU SITE ET DES SERVICES PROPOSÉS</h3>
                        <p>L’utilisation de l'application <b>Stromeety</b> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites.</p>
                        <p>Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs de l'application <b>Stromeety</b> sont donc invités à les consulter de manière régulière.</p>
                        <p>L'application est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par l'équipe de <b>Stromeety</b> qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention sur l'application <b>Stromeety</b> sont mises à jour chaque semaine par l'équipe de développement. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>
                
                    <!--  -->
                    <h3 class="my-5">PORTÉE DES CONTENUS</h3>
                        <p>En dépit du soin apporté à la collecte des informations ainsi qu’à la réalisation du site au moyen des techniques les plus récentes, des erreurs, omissions, inexactitudes, coupures ou additions indépendantes de notre volonté peuvent demeurer ou s’insérer sur l'application <b>Stromeety</b>. Logipro ne peut, pour cette raison, être tenu à garantie, ni expressément, ni implicitement, pour l’ensemble du contenu du site; l’utilisateur du site et des informations qu’il contient reconnaît qu’il en fait usage à ses seuls risques et périls et que l'application <b>Stromeety</b> ne saurait en conséquence voir sa responsabilité engagée à raison de tout préjudice, direct ou indirect, de quelque nature que ce soit résultant pour tout ou partie de l’utilisation des informations du site.</p>
                        <p>De même, Logipro ne ne saurait voir sa responsabilité engagée en raison de la nature ou du contenu des sites référencés sur les pages qui suivent et notamment ceux pour lesquels un accès est permis par l’utilisation des liens hypertextes figurant aux pages de ce site. Conformément à la loi n°78-17 du 6 janvier 1978, vous disposez d’un droit d’accès et de rectification aux données vous concernant.</p>
                
                    <!--  -->
                    <h3 class="my-5">DROIT D’AUTEUR – COPYRIGHT © – LIENS</h3>
                        <h4 class="my-3">La reproduction sur support papier</h4>
                            <p>À l’exception de l’iconographie, la reproduction des pages de ce site sur un support papier est autorisée, sous réserve du respect des conditions suivantes :</p>
                            <ul>
                                <li>gratuité de la diffusion,</li>
                                <li>respect de l’intégrité des documents reproduits (aucune modification, ni altération d’aucune sorte)</li>
                                <li>citation explicite de l'application <b>Stromeety</b> comme source et mention que les droits de reproduction sont réservés et strictement limités.</li>
                            </ul>
                        <h4 class="my-3">La reproduction sur support électronique</h4>
                            <p>La reproduction de tout ou partie de ce site sur un support électronique est autorisée sous réserve de l’ajout de façon claire et lisible de la source (application <b>Stromeety</b>) et de la mention « Droits réservés ».</p>
                            <p>Les informations utilisées ne doivent l’être qu’à des fins personnelles, associatives ou professionnelles ; toute utilisation à des fins commerciales ou publicitaires est exclue.</p>
                    
                    <!--  -->
                    <h3 class="my-5">COOKIES</h3>
                        <p>La navigation sur l'application <b>Stromeety</b> est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.</p>
                        <p>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des cookies :</p>
                    
                        <p>Sous <b>Chrome</b> : onglet paramètre / afficher les paramètres avancés</p>

                        <ul>
                            <li>Pour activer les cookies, sélectionnez Autoriser le stockage des données locales (recommandé).</li>
                            <li>Pour désactiver les cookies, sélectionnez Interdire à tous les sites de stocker des données.</li>
                            <li>Cliquez sur OK pour enregistrer.</li>
                        </ul>
                        <br>
                        <p>Sous <b>Internet Explorer</b> : onglet outil / options internet.</p>
                        <ul>
                            <li>Cliquez sur Confidentialité et choisissez Bloquer tous les cookies.</li>
                            <li>Validez sur OK.</li>
                        </ul>

                    <!--  -->
                    <h3 class="my-5">DROIT APPLICABLE ET ATTRIBUTION DE JURIDICTION</h3>
                        <p>Tout litige en relation avec l’utilisation de l'application <b>Stromeety</b> est soumis à la loi française.</p>
                        <p>En cas de litige, les tribunaux Français seront seuls compétents.</p>

                    <!--  -->
                    <h3 class="my-5">PROTECTION DES DONNÉES PERSONNELLES</h3>
                        <h4 class="my-3">Déclaration à la CNIL</h4>
                            <p>Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l’égard des traitements de données à caractère personnel) relative à l’informatique, aux fichiers et aux libertés, le site a fait l’objet d’une déclaration auprès de la Commission nationale de l’informatique et des libertés (<a href="www.cnil.fr">www.cnil.fr</a>).</p>  
                        <h4 class="my-3">Droit d’accès</h4>
                            <p>En application de cette loi, les internautes disposent d’un droit d’accès, de rectification, de modification et de suppression concernant les données qui les concernent personnellement. Ce droit peut être exercé par voie postale ou par voie électronique : <a href="mailto:contact@stromeety.com">contact@stromeety.com</a></p>
                            <p>Les informations personnelles collectées ne sont en aucun cas confiées à des tiers hormis pour l’éventuelle bonne exécution de la prestation commandée par l’internaute.</p>
                        <h4 class="my-3">Confidentialité</h4>
                            <p>Vos données personnelles sont confidentielles et ne seront en aucun cas communiquées à des tiers hormis pour la bonne exécution de la prestation.</p>
                </v-card-text>
            </v-card>   
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'Mention',
    data() {
        return {
            alertMention: false
        };
    },
    methods: {
        showMentions() {
            this.alertMention = true;
        },
    }
}
</script>