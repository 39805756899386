<template>
  <div>
    <h2 :class="documents.length == 0 ? 'my-5' : 'mt-5'">Documents</h2>
    <div v-if="documents.length == 0">
      Aucun documents
    </div>
    <div v-else>
      <span v-for="document in documents" :key="document.name">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              icon
              :color="document.format == 'pdf' ? '#F40F02' : '#2f78d7'"
              v-bind="attrs"
              v-on="on"
              @click="uploadFile(document.name)"
            >
              <v-icon v-if="document.format == 'pdf'">mdi-file-pdf-box</v-icon>
              <v-icon v-if="document.format == 'jpg' || document.format == 'png' || document.format == 'jpeg'">mdi-image</v-icon>
            </v-btn>
          </template>
          <span>{{document.name}} </span>
          
        </v-tooltip>
      </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Document",
  props: ["eventData"],
  data: () => ({
    documents: []
  }),
  created() {
    this.loadDocuments(this.eventData.id);
  },
  methods: {
    loadDocuments(idEvent) {
      axios({
        method: "get",
        url: this.apiUrl + "/documents/"+idEvent,
        headers: {
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        }
      }).then(
        (response) => {
          this.documents = response.data.data
        });
        (error) => {
          this.globalErrorMethod(error)
        }
    },
    uploadFile(nameDocument) {
      axios({
        method: "get",
        url: this.apiUrl + "/documents/"+nameDocument,
        headers: {
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        },
        responseType: 'blob'
      }).then(
        (response) => {
          this.file = response.data
          const url = window.URL.createObjectURL(new Blob([this.file]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nameDocument);
          document.body.appendChild(link);
          link.click();
        });
        (error) => {
          this.globalErrorMethod(error)
        }
    }
  }
};
</script>

<style scoped>
.v-tooltip__content {
  font-size: 20px !important;
  padding:10px !important;
}
</style>