<template>
  <div>
    <h2 class="mb-5">Informations de l'évènement</h2>

    <b>Type</b> : {{ eventData.type }} <br />
    <b>Salon</b> : {{ eventData.room }} <br />
    <b>Date</b> : {{ eventData.dateF }} <br />
    <b>Durée</b> : {{ eventData.duration }} <br />
    <b>Description</b> : {{ eventData.description }}
  </div>
</template>

<script>
export default {
  name: "Info",
  props: ["eventData"]
};
</script>