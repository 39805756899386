<template>
  <div>
        <h1>Mes évènements</h1>
        <v-row justify="center" >
          <v-col cols="11">
            <Create v-if="$globalData.isAdmin == true" ref="Create" :rooms="rooms" @refreshData="initialize()" @resetForms="resetForm()"></Create>
            <Calendar ref="Calendar" :events="events"></Calendar>
            <Table ref="Table" :events="list" :rooms="rooms" @refreshData="initialize()"></Table>
          </v-col>
        </v-row>
        <HelpPage v-if="!$vuetify.breakpoint.smAndDown"></HelpPage>
  </div>
</template>

<script>
import Calendar from './events/Calendar'
import Create from './events/Create'
import Table from './events/Table'

import HelpPage from './HelpPage'

import axios from "axios";

export default {
  name: 'EventPage',
  components: {
    Create,
    Calendar,
    Table,
    HelpPage
  },
  data: () => ({
    events: [],
    rooms: [],
    list: [],
  }),
  created() {
    this.initialize();
  },
  methods: {
    resetForms() {
      this.$refs.Create.resetForm();
    },
    showEventFromUrl() {
      if (this.$route.query.event) {
        const eventId = this.$route.query.event;
        const eventFromUrl = this.events.filter(i => i.id == eventId)
        this.$refs.Table.showItem(eventFromUrl[0]);
      }
    },
    sortEvents() {
      this.list = []
      this.events.forEach((value) => {
        if (value.status == 1) {
          this.list.push(value);
        }
      });
      this.events.forEach((value) => {
        if (value.status == 2) {
          this.list.push(value);
        }
      });
      this.events.forEach((value) => {
        if (value.status == 0) {
          this.list.push(value);
        }
      });
    },
    initialize() {
      axios({
        method: 'get',
        url: this.apiUrl+'/evenements/list',
        headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
        }
      })
      .then((response) => {
        this.events = response.data;
        this.$refs.Calendar.updateRange(response.data);
        this.sortEvents();
        this.showEventFromUrl();
      }, (error) => {
        this.globalErrorMethod(error)
        this.errorAlert = true;
      });

      axios({
        method: 'get',
        url: this.apiUrl+'/rooms',
        headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
        }
      })
      .then((response) => {
        this.rooms = response.data;
      }, (error) => {
        this.globalErrorMethod(error)
        this.errorAlert = true;
      });

    }
  }
}
</script>
