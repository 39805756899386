<template>
  <div>
    <SuccessAlert ref="SuccessAlert"></SuccessAlert>
    <v-dialog v-model="dialog" max-width="500px">
      <template
        v-slot:activator="{ on, attrs }"
        v-if="$globalData.isAdmin == true"
      >
        <v-btn color="secondary" dark class="mb-2" v-bind="attrs" v-on="on">
          Créer un salon
        </v-btn>
      </template>
      <v-card>
        <v-form ref="form" @submit.prevent="save" lazy-validation>
          <v-toolbar color="primary" dark>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <ErrorAlert ref="ErrorAlert"></ErrorAlert>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="rules.required"
                    label="Nom"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="close"> Retour </v-btn>
            <v-btn color="secondary" type="submit">
              {{ formButton }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Êtes-vous sûr de vouloir supprimer le salon {{ editedItem.name }}
          {{ editedItem.firstname }} ?
          </v-card-title>
          <v-card-text>
          <v-alert
            dense
            outlined
            type="error"
          >
            ATTENTION ! Cette action va aussi entrainer la <strong>suppression de tous les évènements</strong> qui se sont déroulé dans ce salon.
          </v-alert>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="closeDelete">Retour</v-btn>
          <v-btn color="secondary" @click="deleteItemConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-row v-if="salons.length > 0" class="d-flex flex-wrap">
        <v-col lg="4" md="6" sm="12" v-for="salon in salons" :key="salon.id">
          <v-card
            class="mt-4 mr-4"
            width="100%"
            :style="
              salon.inProgress.count != 0 ? {'box-shadow': '1px 1px 7px 1px #006EB2'} : {}
            "
          >
            <v-toolbar class="primary" dark>
              <v-card-title>{{ salon.name }}</v-card-title>
            </v-toolbar>
            <v-card-text>
              <p v-if="salon.inProgress.count != 0" class="font-weight-black">Un évènement est en cours.</p>
              <p v-else>{{ salon.nextEvents }}</p>
              
              <div class="text--primary">Nombre d'enregistrement : {{ salon.nbEvents }}</div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="secondary"
                :to="{ name: 'room', params: { id: salon.id } }"
              >
                Accéder
              </v-btn>
              <v-btn
                color="primary"
                v-if="salon.inProgress.count != 0"
                :to="{ name: 'meeting', params: { id: salon.inProgress.inProgress[0].id }, query: { method: 'open' } }"
              >
                Rejoindre
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="editItem(salon)"
                title="Éditer"
                v-if="$globalData.isAdmin == true"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="deleteItem(salon)"
                title="Supprimer"
                v-if="$globalData.isAdmin == true"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="text-center">
          <span>Vous n'avez aucun salon</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SuccessAlert from "../alerts/Success.vue"
import ErrorAlert from "../alerts/Error.vue"

export default {
  name: "Table",
  components: { 
      SuccessAlert, 
      ErrorAlert
  },
  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    image: "@/assets/images/logo_white.png",
    headers: [
      { text: "Nom", value: "name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    emailRules: [
      (value) =>
        !!value ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
        "Email invalide.",
    ],
    rules: {
      required: [(v) => !!v || "Champ requis"],
    },
    salons: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
    },
    defaultItem: {
      id: "",
      name: "",
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Créer un salon" : "Éditer";
    },
    formButton() {
      return this.editedIndex === -1 ? "Créer" : "Éditer";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios
        .get(this.apiUrl + "/rooms", {
          headers: {
            "STROMEETY-AUTH-TOKEN": this.$globalData.token,
          }
        })
        .then((response) => (
          this.salons = response.data
        ),
        (error) => {
          this.globalErrorMethod(error)
        }
        );
    },

    editItem(item) {
      this.editedIndex = this.salons.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.salons.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios({
        method: "delete",
        url: this.apiUrl + "/rooms",
        headers: {
          "STROMEETY-AUTH-TOKEN": this.$globalData.token,
        },
        data: {
          name: this.editedItem.name,
        },
      }).then(
        (response) => {
          this.salons.splice(this.editedIndex, 1);
          this.closeDelete();
          this.$refs.SuccessAlert.popAlert(response.data);
        },
        (error) => {
          this.globalErrorMethod(error)
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
        }
      );
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      // Si form Edit
      if (this.editedIndex > -1) {
        axios({
          method: "put",
          url: this.apiUrl + "/rooms",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "STROMEETY-AUTH-TOKEN": this.$globalData.token,
          },
          data: {
            id: this.editedItem.id,
            name: this.editedItem.name,
          },
        }).then(
          (response) => {
            this.$refs.SuccessAlert.popAlert(response.data);
            Object.assign(this.salons[this.editedIndex], this.editedItem);
            this.close();
            this.$refs.form.reset();
          },
          (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
      } else {
        // Si form Create
        axios({
          method: "post",
          url: this.apiUrl + "/rooms",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "STROMEETY-AUTH-TOKEN": this.$globalData.token,
          },
          data: {
            name: this.editedItem.name,
            firstname: this.editedItem.firstname,
            email: this.editedItem.email,
          },
        }).then(
          (response) => {
            this.$refs.SuccessAlert.popAlert(response.data);
            this.salons.push(this.editedItem);
            this.close();
            this.initialize();
          },
          (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
