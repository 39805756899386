<template>
  <div>
    <!-- v-if="$vuetify.breakpoint.xsOnly" -->
    <a title="Rejoindre">
      <div v-for="(item, i) in activeEvents" :key="i" class="my-3" >
        <v-alert
            v-model="alert"
            color="primary"
            border="left"
            colored-border
            style="box-shadow: 1px 1px 7px 1px #006EB2"
            class="my-0"
            @click="toMeeting(item.id)"
            dense
        >
            <v-row>
                <v-col 
                  cols="12" 
                  :class="$vuetify.breakpoint.xsOnly ? 'd-flex align-center pa-0' : 'd-flex align-center pa-0'"
                  
                >
                    <v-list-item>
                      <v-list-item-icon class="mx-1">
                        <v-icon color="primary">mdi-account-group</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="font-weight-bold ml-2">{{ item.name }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!$vuetify.breakpoint.xsOnly">
                      <v-list-item-content class="text-center">
                        <v-list-item-subtitle>Date</v-list-item-subtitle>
                        <v-list-item-title>{{ item.dateF }} ({{ item.durationF }})</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item>
                          <!-- Compte à rebours pour non admin -->
                          <v-list-item-title style="text-align: right;" v-if="item.status == 2 && $globalData.isAdmin == false">Commence dans {{ item.minutesLeft }} minutes</v-list-item-title>
                          <!-- Possibilité donné à l'admin de démarrer direct le BBB -->
                          <v-list-item-title style="text-align: right;" v-if="item.status == 2 && $globalData.isAdmin == true">Démarrer</v-list-item-title>
                          <!-- Meeting en cours -->
                          <v-list-item-title style="text-align: right;" v-if="item.status == 1">Rejoindre</v-list-item-title>
                        <v-list-item-icon>
                          <v-icon>
                            mdi-arrow-right
                          </v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-alert>
      </div>
    </a>
  </div>
</template>

<script>
import Vue from 'vue';

  export default {
    name: 'Progress',
    props: {
      events: Array,
    },
    data: () => ({
      alert: true,
      actualFullTime: null,
      activeEvents: [],
      minutesAlert: 11,
      actualFullDate: null,
      anReactiveArray: null
    }),
    created () {
      this.checkIfEventOnline()
      this.checkIfEventStartSoon()
      this.refreshActualEvents = setInterval(() => {
        this.checkEvents()
      }, 10000)
    },
    beforeDestroy: function () {
      clearInterval(this.refreshActualEvents);
    },
    methods: {
        calculateMinutesBetweenDateAndNow(date) {
          const actualDate = new Date();
          const dateEvent = new Date(date)

          var dif = (dateEvent - actualDate);
          dif = Math.round((dif/1000)/60);
          return dif
        },
        toMeeting (id) {
          this.$router.push({ name: 'meeting', params: { id: id }, query: { method: 'open' } })
        },
        checkEvents() {
          this.checkIfEventOnline()
          this.checkIfEventStartSoon()
        },
        checkIfEventOnline () {
          this.events.forEach((item) => {
            // Si un event est en cours, on l'ajoute en alerte
            if (item.status == 1) {
              this.activeEvents.indexOf(item) === -1 ? this.activeEvents.push(item) : '';  
            }
          })
        },
        checkIfEventStartSoon () {
          // On vérifie tous les events auquels on participe
          this.events.forEach((item) => {
            var difMinutes = this.calculateMinutesBetweenDateAndNow(item.date.date);
            if (difMinutes === 0) {
              let index = this.activeEvents.findIndex(events => events.name === item.name );
              this.activeEvents[index].status = 1
              Vue.set(this.activeEvents, index, item)
            } else if (difMinutes < this.minutesAlert && difMinutes > 0 && item.status != 1) {
              if (this.activeEvents.indexOf(item) === -1) {
                item.minutesLeft = difMinutes
                this.activeEvents.push(item)
              } else {
                let index = this.activeEvents.findIndex(events => events.name === item.name );
                this.activeEvents[index].minutesLeft = difMinutes
                Vue.set(this.activeEvents, index, item)
              }
            }
          })
        }
    }
  }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
