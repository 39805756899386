<template>
  <div>
    <!-- <h1 class="text-center" v-if="titleState == false">{{ title }}</h1> -->
    <v-row justify="center">
      <v-col cols="11" class="pa-0">
        <Control
          ref="Control"
          @showResults="showResultsVote"
          @getList="getList"
        ></Control>
        <Vote
          ref="Vote"
          @changeEtat="voteChangeEtats"
          @actualVote="setActualVote"
          @getList="getList"
        ></Vote>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

import Control from "./meeting/Control";
import Vote from "./meeting/Vote";

export default {
  name: "MeetingPage",
  components: {
    Control,
    Vote,
  },
  data: () => ({
    etatDuVote: null,
  }),
  created() {
    this.initialize();
  },
  beforeRouteLeave(to, from, next) {
    if (this.eventSource) {
      this.eventSource.close();
    }
    next();
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.closeMercure);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.closeMercure);
    this.$cookies.remove('mercureAuthorization')
  },
  methods: {
    initialize() {
      this.mercure();
    },
    closeMercure(event) {
      this.eventSource.close();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    },
    mercure() {
      axios
        .get(this.apiUrl + "/mercure/"+this.$route.params.id, {
          headers: {
            "STROMEETY-AUTH-TOKEN": this.$globalData.token
          }
        })
        .then((response) => {
          this.$cookies.set(
            "mercureAuthorization",
            response.data.token,
            0,
            "/mercure/.well-known/mercure",
            "",
            "Secure",
            "None"
          );
          const url = new URL(this.apiUrlAuth + "mercure/.well-known/mercure");
          
          url.searchParams.append("topic", "event/" + this.$route.params.id);
          // The URL class is a convenient way to generate URLs such as https://localhost/.well-known/mercure?topic=https://example.com/books/{id}&topic=https://example.com/users/dunglas

          this.eventSource = new EventSource(url, { withCredentials: true });
          // The callback will be called every time an update is published
          
          this.eventSource.onmessage = (e) => {
            const dataJson = JSON.parse(e.data);
            switch (dataJson.type) {
              case 'vote':
                this.launchVote(dataJson.id);
                break;
              case 'status':
                this.launchStatus(dataJson.status);
                break;
              case 'results':
                this.launchResults(dataJson.data);
                break;
              default:
                console.log(`Une erreur est survenue.`);
            }
          };
        },
        (error) => {
            this.globalErrorMethod(error)
        }
        );
    },
    launchVote(id) {
      this.$refs.Vote.loadVote(id);
    },
    launchStatus(status) {
      this.$refs.Control.controlStatus(status);
    },
    launchResults(data) {
      this.$refs.Vote.stockResults(data);
    },
    voteChangeEtats(etat) {
      this.$refs.Control.voteSetEtat(etat);
    },
    setActualVote(idVote) {
      this.$refs.Control.setActualVote(idVote);
    },
    showResultsVote(votes) {
      this.$refs.Vote.showResultsFromBtn(votes);
    },
    getList() {
      axios({
        method: "get",
        url: this.apiUrl + "/votes/"+this.$route.params.id,
        headers: {
          "STROMEETY-AUTH-TOKEN": this.$globalData.token,
        },
      }).then(
        (response) => {
          this.votes = response.data.votes;
          if (this.$globalData.isAdmin == true) {
            this.$refs.Control.getListVotes(response.data.votes);
          }
          this.$refs.Vote.getListVotes(response.data.votes);
          this.$refs.Vote.sortVotes(response.data.votes);
        },
        (error) => {
          this.globalErrorMethod(error);
        }
      );
    },
  },
};
</script>
