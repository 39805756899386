<template>   
  <div id="app">
    <v-app>
      <Header v-if="!$route.path.includes('login') && !$route.path.includes('confirmation') && !$route.path.includes('password')"></Header>
      <v-main :class="$vuetify.breakpoint.smAndDown? 'mt-16' : ''">
        <v-container fluid>
          <transition name="fade">
            <router-view></router-view>
          </transition>
        </v-container>

      </v-main>
      <Footer></Footer>
    </v-app>
  </div>
</template>

<script>

import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
};
</script>


<style lang="scss">
@import url('./scss/styles.scss');

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}
.fade-enter-active {
  transition-delay: .25s;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}

</style>
