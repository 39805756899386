<template>
  <div>
    <!-- Arrêter le streaming -->
    <v-dialog
    v-model="dialogConfirmEditKeys"
    persistent
    max-width="650"
    >
    <v-card>
        <v-card-title class="text-h5">
        Êtes vous sûr de vouloir changer les clés de streaming ?
        </v-card-title>
        <v-card-text>
          <span class="text-small">Vous pouvez les modifier à n'importe quel moment.</span>
        </v-card-text>
        <v-card-actions :style="$vuetify.breakpoint.xsOnly ? 'flex-wrap: wrap !important;' : ''">
            <v-btn
                color="secondary"
                text
                @click="dialogConfirmEditKeys = false"
                :loading="loadingUpdateKeys"
                :class="$vuetify.breakpoint.xsOnly ? 'mobileDialogLeaveMeeting' : ''"
            >
                Retour
            </v-btn>
            <v-btn
                color="secondary"
                @click="updateKeys()"
                :loading="loadingUpdateKeys"
                :class="$vuetify.breakpoint.xsOnly ? 'mobileDialogLeaveMeeting' : ''"
            >
                Modifier
            </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
    <v-sheet
        color="white"
        elevation="4"
        rounded
        shaped
        class="pa-4 mb-2"
        fill-height
    >
        <h2>Clés streaming Youtube et Twitch</h2>
        <span>Les clés de streaming sont propre à votre organisation <b>{{ organizationName }}</b></span>

        <v-divider class="my-5"></v-divider>

        <SuccessAlert ref="SuccessAlert"></SuccessAlert>
        <ErrorAlert ref="ErrorAlert"></ErrorAlert>

        <v-form
        ref="form"
        @submit.prevent="valid"
        lazy-validation
        >
          <v-row>
            <v-col cols="12" md="12" lg="6">
              <v-text-field
                v-model="links.youtube"
                prepend-icon="mdi-youtube"
                type="text"
                label="Lien RTMP Youtube"
                placeholder="exemple : A1b2-c4D5-Efg6-7h8j"
                :loading="loaderStreamingLinks"
                :disabled="loaderStreamingLinks"
                color="#FF0000"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="6">
              <v-text-field
                v-model="links.twitch"
                prepend-icon="mdi-twitch"
                type="text"
                label="Lien RTMP Twitch"
                placeholder="exemple : live_8494894_OjifoehfooiHDIzoHOOI"
                :loading="loaderStreamingLinks"
                :disabled="loaderStreamingLinks"
                color="#6441a5"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn color="secondary" type="submit" @click="dialogConfirmEditKeys = true" large>Modifier</v-btn>
        </v-form>
    </v-sheet>
  </div>
</template>
<script>
import axios from 'axios';
import SuccessAlert from "../alerts/Success.vue"
import ErrorAlert from "../alerts/Error.vue"

export default {
  name: 'StreamingLinks',
  components: { 
      SuccessAlert, 
      ErrorAlert
  },
    data () {
      return {
          links: {
            youtube: 'exemple',
            twitch: 'example'
          },
          dialogConfirmEditKeys: false,
          loadingUpdateKeys: false,
          idOrganization: null,
          loaderStreamingLinks: false // Active le loading tant que axios se charge
        }
    },
    created () {
      const user = JSON.parse(localStorage.getItem('user'));
      this.organizationName = user.organization[0].name
      this.loadStreamingLinks();
    },
    methods: {
      loadStreamingLinks () {
        this.loaderStreamingLinks = true;
        axios({
          method: 'get',
          url: this.apiUrl+'/parametres/rtmp',
            headers: {
                'STROMEETY-AUTH-TOKEN': this.$globalData.token
            }
        })
        .then((response) => {
            this.links.youtube = response.data[0].youtube;
            this.links.twitch = response.data[0].twitch;
            this.idOrganization = response.data[0].organization;
            this.loaderStreamingLinks = false;
        }, (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
        });
      },
      updateKeys () {
         axios({
            method: 'put',
            url: this.apiUrl+'/parametres/rtmp/'+this.idOrganization,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'STROMEETY-AUTH-TOKEN': this.$globalData.token
            },
            data: {
              youtube: this.links.youtube,
              twitch: this.links.twitch
            }
          })
          .then((response) => {
            this.$refs.SuccessAlert.popAlert(response.data);
            this.dialogConfirmEditKeys = false;
          }, (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          });
      }
    }
}
</script>