<template>
    <div>
        <v-row justify="center">
          <v-col cols="11">
            <v-row>
              <v-col sm="12">
                <Pouvoir ref="Pouvoir"></Pouvoir>
              </v-col>
              <v-col sm="12">
                <Vote ref="Vote" @eventStatus="setEventStatus"></Vote>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <HelpPage v-if="!$vuetify.breakpoint.smAndDown"></HelpPage>
    </div>
</template>

<script>
import Pouvoir from './vote/Pouvoir'
import Vote from './vote/Vote'

import HelpPage from './HelpPage'

export default {
    name: 'VotePage',
    components: {
        Pouvoir,
        Vote,
        HelpPage
    },
    methods: {
      setEventStatus(status) {
        this.$refs.Pouvoir.setStatus(status);
        this.$refs.Vote.setStatus(status);
      }
    }
}
</script>
