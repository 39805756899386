<template>
  <div>
    <h1>{{ title }}</h1>
  <v-row class="d-flex flex-wrap mt-5 mb-10" >
    <v-col lg="12" md="6" sm="12" v-if="list.length == 0" style="text-align:center">
      Vous ne participez dans aucun évènement dans ce salon.
    </v-col>
    <v-col lg="4" md="6" sm="12" v-for="event in list" :key="event.id">
      <v-card
          class="mt-4 mr-4"
          width="100%"
        >
          <v-toolbar class="primary" dark>
            <v-card-title>{{event.name}}</v-card-title>
          </v-toolbar>
          <v-card-text>
            <div class="text--primary mb-5">
              Le {{ event.dateF }}, d'une durée de {{ event.durationF }}
            </div>
            <p v-if="event.description">{{ event.description }}</p>
            <p v-else>Aucune description</p>
          </v-card-text>
          <v-card-actions>
            <v-btn  color="secondary" :to="{ name: 'meeting', params: { id: event.id }, query: { method: 'open' }}">
              {{ $globalData.isAdmin == true ? 'Démarrer' : 'Rejoindre' }}
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-col>
  </v-row>

  <v-sheet color="white"
      rounded
      shaped
      class="pa-5"
    >
    <h2>Historique des enregistrements</h2>
    <v-divider class="my-5"></v-divider>
    <v-data-table
      :headers="headers"
      :items="dataRecords"
      sort-by="startTime"
      :sort-desc="true"
      locale="fr"
      :disable-sort="$vuetify.breakpoint.xsOnly"
    >
        <template v-slot:[`item.startTime`]="{ item }">
            {{ convertTime(item.startTime) }}
        </template>
        <template v-slot:[`item.endTime`]="{ item }">
            {{ convertTime(item.endTime) }}
        </template>
        <template v-slot:[`item.playbackUrl`]="{ item }" >        
            <a :href="item.playbackUrl" target="_blank">
                Voir la rediffusion
            </a>
        </template>
        <template v-slot:[`item.state`]="{ item }">
            <div v-if="item.state === 'published'">
                Publié
            </div>
        </template>
    </v-data-table>
  </v-sheet>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Control",
  data() {
    return {
      headers: [
        { text: 'Début', value: 'startTime', class:'primary white--text' },
        { text: 'Fin', value: 'endTime', class:'primary white--text' },
        { text: 'Evenements', value: 'name', class:'primary white--text' },
        { text: 'Rediffusion', value: 'playbackUrl', class:'primary white--text' },
        { text: 'Etat', value: 'state', class:'primary white--text' },
      ],
      dataRecords: [],
      noEvents: false,
      list: [],
      title: null
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    idParams() {
      const params = new URLSearchParams();
      params.append("id", this.$route.params.id);
      return params;
    },
  },
  methods: {
    initialize() {
      // this.$emit('changeTitle', 'test');
      if (this.$globalData.isAdmin == true) {
        this.enter = 1;
      }

      axios.get(this.apiUrl + "/bbb/recordings/"+this.$route.params.id, {
          headers: {
            "STROMEETY-AUTH-TOKEN": this.$globalData.token,            
          }
        })
        .then(
          (response) => {
            this.dataRecords = response.data;
          },
          (error) => {
            this.globalHelper(error)
          }
        );

        axios({
          method: 'get',
          url: this.apiUrl+'/evenements/room/list',
          headers: {
              'STROMEETY-AUTH-TOKEN': this.$globalData.token
          },
          params: {
            roomId: this.$route.params.id
          }
        })
        .then((response) => {
          this.title = response.data.title;
          this.events = response.data.rooms;
          this.sortEvents();
        }, (error) => {
          this.globalErrorMethod(error)
        });

    },
    sortEvents() {
      this.events.forEach((value) => {
        if (value.status == 2 || value.status == 1) {
          this.list.push(value);
        }
      });
    },
    convertTime(time) {
        const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
        const timeDecode = time/1000;
        const date = new Date(timeDecode * 1000);
        const minutes = date.getMinutes();
        const hours = date.getHours();
        const format = date.getDate()+" "+months[date.getMonth()]+" "+date.getFullYear()+" à "+hours+'h'+minutes
        return format
    },
  },
};
</script>

<style lang="scss">
  iframe{
    height: calc(100% - 70px)!important;
  }

  @media (max-width: 568px) {
    .v-btn{
      font-size: 0.6rem!important;
      margin: 4px 3px!important;
    }

    iframe{
      height: calc(100% - 160px)!important;
    }
  }
</style>