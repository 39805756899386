<template>
  <div>

    <!-- Fermer le meeting -->
    <v-dialog
    v-model="dialogCloseBBB"

    persistent
    max-width="650"
    >
    <v-card>
        <v-card-title class="text-h5">
        Êtes vous sûr de vouloir terminer le meeting ?
        </v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions :style="$vuetify.breakpoint.xsOnly ? 'flex-wrap: wrap !important;' : ''">
            <v-btn
                color="secondary"
                text
                @click="dialogCloseBBB = false"
                :class="$vuetify.breakpoint.xsOnly ? 'mobileDialogLeaveMeeting' : ''"
            >
                Retour
            </v-btn>
            <v-btn
                color="error"
                @click="endBBB"
                :loading="loadingDialogCloseBBB"
                :disabled="loadingDialogCloseBBB"
                :class="$vuetify.breakpoint.xsOnly ? 'mobileDialogLeaveMeeting' : ''"
            >
                Arrêter le meeting
            </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>

    



    <v-sheet
      color="white"
      elevation="4"
      rounded
      shaped
      class="pa-5 mb-10"
      v-if="iframeBBB == false"
    >
      <h2 v-if="$globalData.isAdmin == true">Panneau de controle - {{ event.name }}</h2>
      <h2 v-else>{{ event.name }}</h2>


      <v-divider class="my-5"></v-divider>
      <v-alert
        v-model="infoAlertComponent"
        dense
        text
        type="info"
      >
      {{ infoAlertMessage }}
      </v-alert>
      <SuccessAlert ref="SuccessAlert"></SuccessAlert>
      <ErrorAlert ref="ErrorAlert"></ErrorAlert>
      <v-row>
        <!--  -->
        <v-col xs="12" sm="6" md="6">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" :lg="$globalData.isAdmin == true ? '6' : '12'">
              <v-btn
                small
                color="secondary"
                @click="enterBBB"
                :loading="enterBBBloading"
                :disabled="enter == 0 || enterBBBloading"
                style="width:100%"
              > 
                <span v-if="$globalData.isAdmin == true">{{ statusBBB ? 'Entrer' : 'Démarrer' }}</span>
                <span v-else>Entrer</span>
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" v-if="$globalData.isAdmin == true">
              <StartStreaming ref="StartStreaming"></StartStreaming>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.xlOnly" class="d-flex justify-center" cols="12" xs="12" sm="12" md="12" lg="6">
                <v-progress-circular
                  indeterminate
                  color="secondary"
                  v-if="statusBBB === 2"
                ></v-progress-circular>
                <div v-if="statusBBB === 1">
                  <v-avatar color="success" size="30" class="mr-2"></v-avatar>
                  <span>En cours</span>
                </div>
                <div v-if="statusBBB === 0">
                  <v-avatar color="red" size="30" class="mr-2"></v-avatar>
                  <span>Hors ligne</span>
                </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" v-if="$globalData.isAdmin == true">
              <v-btn
                dark
                small
                color="primary"
                @click="linkYoutube"
                style="width:100%"
              >
                <v-icon left> mdi-youtube </v-icon>
                Ma chaine Youtube
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-divider v-if="!$vuetify.breakpoint.xsOnly" vertical></v-divider>
        <v-col cols="12" xs="12" sm="6" md="6">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" v-if="$globalData.isAdmin == true">
              <StartVote ref="StartVote" @loadVotes="emitList"></StartVote>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" :lg="$globalData.isAdmin == true ? '6' : '12'">
              <v-btn
                dark
                small
                color="primary"
                @click="showResults()"
                style="width:100%"
              >
                <v-icon left small> mdi-clipboard-list-outline </v-icon>
                Voir les résultats
              </v-btn>
            </v-col>
            <v-col v-if="!$vuetify.breakpoint.xlOnly" class="d-flex justify-center" cols="12" xs="12" sm="12" md="12" lg="12">
                <v-progress-circular
                  indeterminate
                  color="secondary"
                  v-if="statusBBB === 2"
                ></v-progress-circular>
                <div v-if="statusBBB === 1">
                  <v-avatar color="success" size="30" class="mr-2"></v-avatar>
                  <span>En cours</span>
                </div>
                <div v-if="statusBBB === 0">
                  <v-avatar color="red" size="30" class="mr-2"></v-avatar>
                  <span>Hors ligne</span>
                </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
    <div v-if="iframeBBB == true">
      <v-btn
        v-model="panelMeeting"
        icon
        color="grey"
        @click="hidePanelMeeting = !hidePanelMeeting"
        style="margin-left:15px !important"
        v-if="$vuetify.breakpoint.xsOnly"
      >
        <v-icon>mdi-currency-eth</v-icon>
        <span>Cacher</span>
      </v-btn>
      <SuccessAlert ref="SuccessAlert"></SuccessAlert>
      <ErrorAlert ref="ErrorAlert"></ErrorAlert>
    </div>

    <v-col cols="12" v-if="iframeBBB == true" class="pa-0">
      <v-row class="mt-1" v-if="hidePanelMeeting">
        <!--  -->
        <v-col xs="12" sm="6" md="6">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" class="p-mobile-4">
              <v-btn small color="red" @click="dialogCloseBBB = true" v-if="$globalData.isAdmin == true" class="white--text" style="width:100%">
                Terminer
              </v-btn>
              <v-btn small color="red" @click="endBBBUser" v-if="$globalData.isAdmin == false" class="white--text" style="width:100%">
                Sortir
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" class="p-mobile-4" v-if="$globalData.isAdmin == true">
              <StartStreaming ref="StartStreaming" @setEtat="setEtat(etat)"></StartStreaming>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.lgAndUp" class="d-flex justify-center" cols="12" xs="12" sm="12" md="12" lg="6">
                <v-progress-circular
                  indeterminate
                  color="secondary"
                  v-if="statusBBB === 2"
                ></v-progress-circular>
                <div v-if="statusBBB === 1">
                  <v-avatar color="success" size="30" class="mr-2"></v-avatar>
                  <span>En cours</span>
                </div>
                <div v-if="statusBBB === 0">
                  <v-avatar color="red" size="30" class="mr-2"></v-avatar>
                  <span>Hors ligne</span>
                </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" class="p-mobile-4" v-if="$globalData.isAdmin == true">
              <v-btn
                dark
                small
                color="primary"
                @click="linkYoutube"
                style="width:100%"
              >
                <v-icon left> mdi-youtube </v-icon>
                Ma chaine Youtube
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-divider v-if="!$vuetify.breakpoint.xsOnly" vertical></v-divider>
        <v-col cols="12" xs="12" sm="6" md="6">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" class="p-mobile-4" v-if="$globalData.isAdmin == true">
              <StartVote ref="StartVote" @loadVotes="emitList"></StartVote>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" class="p-mobile-4">
              <v-btn
                dark
                small
                color="primary"
                @click="showResults()"
                style="width:100%"
              >
                <v-icon left small> mdi-clipboard-list-outline </v-icon>
                Voir les résultats
              </v-btn>
            </v-col>
            <v-col v-if="!$vuetify.breakpoint.lgAndUp" class="d-flex justify-center p-mobile-4" cols="12" xs="12" sm="12" md="12" lg="12">
                <v-progress-circular
                  indeterminate
                  color="secondary"
                  v-if="statusBBB === 2"
                ></v-progress-circular>
                <div v-if="statusBBB === 1">
                  <v-avatar color="success" size="30" class="mr-2"></v-avatar>
                  <span>En cours</span>
                </div>
                <div v-if="statusBBB === 0">
                  <v-avatar color="red" size="30" class="mr-2"></v-avatar>
                  <span>Hors ligne</span>
                </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <iframe
        width="100%"
        style="position: absolute; bottom: 0; right: 0%; border: 0;"
        :class="$vuetify.breakpoint.xsOnly ? 'iframeBBBMobile' : 'iframeBBB'"
        :style="$vuetify.breakpoint.xsOnly ? (hidePanelMeeting ? 'top:310px; height: 100%' : 'top:50px; height: calc(100% - 50px) !important') : ''"
        :src="linkbbb"
        allow="fullscreen; camera *;microphone *; display-capture"
      >
      </iframe>
    </v-col>
  </div>
</template>
<script>
import axios from "axios";
import SuccessAlert from "../alerts/Success.vue"
import ErrorAlert from "../alerts/Error.vue"
import StartVote from "./panel/StartVote.vue"
import StartStreaming from "./panel/StartStreaming.vue"

export default {
  name: "Control",
  components: { 
    SuccessAlert, 
    ErrorAlert, 
    StartVote,
    StartStreaming
  },
  data() {
    return {
      meetingId: "",
      statusBBB: 2, // 0: Hors Ligne. 1: En live. 2: En attente du status
      status: false,
      nbParticipants: 0,
      timeStart: "",
      enter: 0,
      headers: [
        { text: "Début", value: "startTime", class: "primary white--text" },
        { text: "Fin", value: "endTime", class: "primary white--text" },
        {
          text: "Evenements",
          value: "playback.format.link",
          class: "primary white--text",
        },
        {
          text: "Rediffusion",
          value: "playback.format.url",
          class: "primary white--text",
        },
        { text: "Etat", value: "state", class: "primary white--text" },
      ],
      linkbbb: "",
      iframeBBB: false,
      btnMargin: "",
      mlAuto: "",
      iframeHeight: "",
      votes: [],
      voteActif: null,
      eventSource: null,
      event: '',
      voteEtat: 0,
      isMobile: false,
      dialogCloseBBB: false,
      enterBBBloading: false,
      infoAlertComponent: false,
      infoAlertMessage: 'L\'évènement n\'a pas encore commencé. En attente de l\'organisateur.',
      hidePanelMeeting: true,
      loadingDialogCloseBBB: false
    };
  },
  created() {
    this.initialize();
    this.getStatus();
    this.getRoom();
    this.$emit('getList');
  },
  computed: {
    idParams() {
      const params = new URLSearchParams();
      params.append("id", this.$route.params.id);
      return params;
    },
    idParamsMercure() {
      const params = new URLSearchParams();
      params.append("idEvent", this.$route.params.id);
      params.append("status", true);
      return params;
    },
  },
  methods: {
    initialize() {
      if (this.$globalData.isAdmin == true) {
        this.enter = 1;
      }
    },
    // On axios la liste des Votes dans MeetingPage.vue
    emitList () {
      this.$emit('getList');
    },
    // On envoi les data de MeetinPage.vue à StartVote
    getListVotes(data) {
      this.$refs.StartVote.loadVoteList(data);
    },
    // A l'arrivé sur la page, on ouvre direct le BBB si dansl'url on a "?method=open"
    autoOpen() {
      if (this.$globalData.isAdmin == true) {
        if (this.statusBBB === 0) {
          this.createBBB();
        } else if (this.statusBBB === 1) {
          this.joinBBB();
        }
      } else {
        if (this.statusBBB === 0) {
          this.infoAlert('L\'évènement n\'a pas encore commencé. En attente de l\'organisateur.')
        } else {
          this.joinBBB();
        }
      }


    },
    voteSetEtat (etat) {
      this.voteEtat = etat;
    },
    infoAlert (message) {
      this.infoAlertComponent = true;
      this.infoAlertMessage = message;
    },
    getRoom() {
      axios
        .get(this.apiUrl + "/evenements/"+this.$route.params.id, {
          headers: {
            "STROMEETY-AUTH-TOKEN": this.$globalData.token,
          }
        })
        .then(
          (response) => {
            if (response.data.status == 0) {
              this.$router.push({ name: 'rooms'});
            }
            this.event = response.data;
            this.meetingId = response.data.bbbMeetingID;
          },
          (error) => {
            this.globalErrorMethod(error);
            this.$refs.ErrorAlert.popAlert('Une erreur est survenue.');
          }
        );
    },
    enterBBB() {
      this.enterBBBloading = true;
      // Check if already online. Doit retourner statusBBB = 0 si OFF, statusBBB = 1 si ON 
      
      if (this.statusBBB === 1 && this.iframeBBB === false) {
        this.joinBBB();
      }
      else if (this.statusBBB === 0 && this.$globalData.isAdmin == true) {
        this.createBBB();
      }
      else {
        this.infoAlertComponent = true;
      }
    },
    joinBBB () {
      axios
        .get(this.apiUrl + "/bbb/joinmeeting/event/"+this.$route.params.id, {
          headers: {
            "STROMEETY-AUTH-TOKEN": this.$globalData.token,
          }
        })
        .then(
          (response) => {
            this.linkbbb = response.data.url;
            this.iframeBBB = true;
            this.enterBBBloading = false;
          },
          (error) => {
            this.globalErrorMethod(error);
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
    },
    createBBB () {
      axios
        .get(this.apiUrl + "/bbb/createmeeting/event/"+this.$route.params.id, {
          headers: {
            "STROMEETY-AUTH-TOKEN": this.$globalData.token,
          }
        })
        .then(
          () => {
            this.enterBBBloading = false;
            this.joinBBB();
            this.mercureCreateBBB();
          },
          (error) => {
            this.globalErrorMethod(error);
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
    },
    mercureCreateBBB() {
      if (this.$globalData.isAdmin == true) {
        // Ouverture Mercure
        axios
          .patch(this.apiUrl + "/rooms/"+this.$route.params.id, {
              status: true
          },
          {
            headers: {
              "STROMEETY-AUTH-TOKEN": this.$globalData.token
            }
          })
      }
    },

    endBBBUser() {
      this.$router.push({ name: 'home'});
    },
    endBBB() {
      this.loadingDialogCloseBBB = true;
      this.$emit("hideTitle", false);
      // Ferme mercure
      axios
        .get(
          this.apiUrl + "/bbb/end/"+this.$route.params.id,
          {
            headers: {
              "STROMEETY-AUTH-TOKEN": this.$globalData.token,
            },
          }
        )
        .then(
          (response) => {
            this.$refs.SuccessAlert.popAlert(response.data);
            this.iframeBBB = false;
            this.statusBBB = 0;
            this.dialogCloseBBB = false;
            this.loadingDialogCloseBBB = false;
            this.$router.push({ name: 'home' });
          },
          () => {
            this.$refs.ErrorAlert.popAlert('Un problème est survenu.');
          }
        );
      // Ouverture Mercure
      axios
        .patch(this.apiUrl + "/rooms/"+this.$route.params.id, {
            status: false
        },
        {
          headers: {
            "STROMEETY-AUTH-TOKEN": this.$globalData.token
          }
        }
        )
    },
    linkYoutube() {
      window.open(
        "https://studio.youtube.com/video/zYjmdM_lr_c/livestreaming",
        "_blank"
      );
    },
    getStatus() {
      axios
        .get(this.apiUrl + "/bbb/status/"+this.$route.params.id, {
          headers: {
            "STROMEETY-AUTH-TOKEN": this.$globalData.token,
          }
        })
        .then(
          (response) => {
            this.controlStatus(response.data);
            // Si on est en method=open dans l'url, on démarre automatiquement le BBB
            if (this.$route.query.method && this.$route.query.method == "open" && this.iframeBBB === false) {
              this.autoOpen();
            }
          },
          (error) => {
            this.globalErrorMethod(error);
            this.$refs.ErrorAlert.popAlert('Un problème est survenu.');
          }
        );
    },
    controlStatus(status) {
      if (status['bbb'] == true) {
        this.statusBBB = 1;
        this.enter = 1;
        this.getInfosMeeting();
        // this.enterBBB();
      } else {
        if (this.$globalData.isAdmin == false) {
          this.enter = 0;
        }
        this.statusBBB = 0;
        this.iframeBBB = false;
      }
      // Si on est en method=open dans l'url, on démarre automatiquement le BBB
      if (this.$route.query.method && this.$route.query.method == "open" && this.iframeBBB === false && this.$globalData.isAdmin == false) {
        this.autoOpen();
      }
      // Check status Livestream
      if (status['livestream'] == true) {
        this.$refs.StartStreaming.setEtat(1);
      } else {
        this.$refs.StartStreaming.setEtat(0);
      }
    },
    getInfosMeeting() {
      axios
        .get(this.apiUrl + "/bbb/infos/"+this.$route.params.id, {
          headers: {
            "STROMEETY-AUTH-TOKEN": this.$globalData.token,
          }
        })
        .then(
          (response) => {
            this.nbParticipants = response.data.participantCount;
            const time = response.data.createDate;
            const words = time.split(" ");
            this.timeStart = words[3];
          },
          () => {
            this.$refs.ErrorAlert.popAlert('Un problème est survenu.');
          }
        );
    },
    showResults() {
      this.$emit('showResults', this.votes);
    },
    setActualVote(idEvent) {
      this.voteActif = idEvent
    },



  },
};
</script>

<style lang="scss" scoped>

@media (max-width: 568px) {
  .v-btn {
    font-size: 0.6rem !important;
    margin: 4px 3px !important;
  }
}
</style>