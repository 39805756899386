<template>
  <div class="position:relative;">
    <SuccessAlert ref="SuccessAlert"></SuccessAlert>
    <v-row>
      <v-dialog v-model="dialog" max-width="800px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="secondary" dark v-bind="attrs" v-on="on" class="mb-7">
            Créer un évènement
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
              <v-card-title>
                <span class="headline">Création d'un évènement</span>
              </v-card-title>
          </v-toolbar>
          <v-card>
            <!-- 3 steps -->
            <v-stepper v-model="e1" alt-labels>
              <v-stepper-header v-if="!$vuetify.breakpoint.xsOnly">
                <v-stepper-step :complete="e1 > 1" color="secondary" step="1">
                  Création
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" color="secondary" step="2">
                  Participants
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step color="secondary" step="3">
                  Envoi d'emails
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-header v-if="$vuetify.breakpoint.xsOnly">
                <v-stepper-step :complete="e1 > 1" color="secondary" step="1">
                  Création
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" color="secondary" step="2">
                  Participants
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step color="secondary" step="3">
                  Envoi d'emails
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items class="overflow-y-auto mt-2">
                <v-stepper-content step="1">
                  <Step1 @changeEl="updateEl($event)" ref="Step1" @stepOne="stepOne($event)"  :rooms="rooms"></Step1>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <Step2 @changeEl="updateEl($event)" ref="Step2" @stepTwo="stepTwo($event)"></Step2>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <Step3 @changeEl="updateEl($event)" ref="Step3" @stepThree="stepThree($event)"></Step3>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Step1 from "./Form/Step1.vue";
import Step2 from "./Form/Step2.vue";
import Step3 from "./Form/Step3.vue";
import SuccessAlert from "../alerts/Success.vue"
import axios from 'axios';

export default {
  name: "Create",
  components: { Step1, Step2, Step3, SuccessAlert },
  props: {
    rooms: Array,
  },
  data: () => ({
    dialog: false,
    e1: 1,
    types: ["Visio-conférence", "Webinaire"],
    test: "test",
    time: null,
    menuDate: false,
    menuTime: false,
    menuDuration: false,
    DurationHours: [
      "15mn",
      "30mn",
      "45mn",
      "1h",
      "1h30",
      "2h",
      "2h30",
      "3h",
      "3h30",
      "4h",
      "4h30",
    ],
    stepOneForm: [],
    stepTwoForm: [],
    stepThreeForm: [],
    response: ""
  }),
  created() {
    //
  },
  methods: {
    close() {
      this.dialog = false;
    },
    updateEl(event) {
      this.e1 = event;
    },
    stepOne(event) {
      this.stepOneForm = event
    },
    stepTwo(event) {
      this.stepTwoForm = event
    },
    stepThree(event) {
      this.stepThreeForm = event
      this.$refs.Step3.validButton(true);
      this.submitForm(this.stepOneForm, this.stepTwoForm, this.stepThreeForm);
    },
    resetForm() {
      this.$refs.Step1.resetForm();
      this.$refs.Step2.resetForm();
      this.$refs.Step3.resetForm();
    },
    async uploadDocument(idEvent, type, file, send = false) {
      var data = new FormData()
      data.append("file", file);
      await axios.post(this.apiUrl+'/documents/'+idEvent, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        },
        params: {
          type: type
        }
      })     
      .then(response => {

        this.response = response
        this.e1 = 1,
        this.$emit('refreshData'),
        this.resetForm(),
        this.close();
        if (send == true) {
          this.sendMails(idEvent)
        }
      })
      .catch(error => {
        this.globalErrorMethod(error)
      });
    },
    submitForm(formOne, formTwo, formThree) {
      axios({
        method: 'post',
        url: this.apiUrl+'/evenements',
        headers: {
            'Content-Type': 'multipart/form-data',
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
        },
        data: {
          formOne: formOne,
          formTwo: formTwo
        }
      })
      .then((response) => {
        this.$refs.SuccessAlert.popAlert(response.data.message);
        this.e1 = 1;
        if(formOne.fileBBB) {
          this.uploadDocument(response.data.id, 'fileBBB', formOne.fileBBB).then(() => {})
        }
        if(formThree[0].mailPj) {
          this.uploadDocument(response.data.id, 'fileMail', formThree[0].mailPj, formThree[0].sendEmail).then(() => {})
        }
        if(formThree[0].sendEmail == true && !formThree[0].mailPj) {
          this.sendMails(response.data.id)
        }

        this.$emit('refreshData'),
        this.$refs.Step3.validButton(false);
        this.resetForm(),
        this.close();
      }, (error) => {
        this.globalErrorMethod(error)
      });
    },
    sendMails(id) {
      axios({
        method: "post",
        url: this.apiUrl + "/evenements/mails/"+id,
        headers: {
          'STROMEETY-AUTH-TOKEN': this.$globalData.token
        },
        data: {
          all: true
        },
      }).then(
        () => {
        },
        (error) => {
          this.globalErrorMethod(error)
        }
      );
    },
  },
};
</script>