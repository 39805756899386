<template>
    <div>
        <div>
            <h4 class="py-2">Comment fonctionnent les votes ?</h4>
            <p>Vous pouvez créer les votes quand vous le souhaitez à partir du moment où l'évènement estcréé. Nous vous conseillons de les créer avant l'évènement et pas pendant.<br><br>
            Vous pouvez définir différents paramètres :
                <ul>
                    <li><b>Titre : </b> vous permet d'identifier le vote.</li>
                    <li><b>Question : </b> correspond à la question qui apparaîtra à l'écran.</li>
                    <li><b>Anonyme : </b> le vote est anonyme si il est coché. Celà signifie que les noms des utilisateurs ne seront pas affichés lors de l'affichage des votes et de l'exportation</li>
                    <li><b>Type de réponses : </b> selectionnez les types de réponses possibles.</li>
                    <li><b>Temps : </b> nombre de secondes données aux utilisateurs pour voter. A la fin du compte à rebours, les utilisateurs n'ayant pas voté seront considérés comme "non votant".</li>
                </ul>
            Pour lancer un vote, il vous suffit de cliquer sur "Lancer un vote" dans le menu supérieur lors de la réunion.
            </p>
        </div>
        <div> 
            La page est composé de 2 sections, la configuration des participants et la création et modification des votes.
        </div>

        <v-timeline
            dense
            clipped
            class="mt-5"
        >
            <!-- Participants -->
            <v-timeline-item
            color="primary"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    Participants
                </h3>
                <div>
                    Cette section vous permet de définir les droits de votes de chaque participants.
                <ul>
                    <li><b>Droit de vote : </b> Par défaut, tous les participants ont le droit de vote. Vous pouvez modifier ce droit en activant oud ésactivant cette option.</li>
                    <li><b>Pondération : </b> Par défaut, la pondération est de 10. Cette option vous permet de donner plus d'importance au vote d'une personne. La pondération est prise en compte lors du pourcentage à la finalisation des votes.</li>
                    <li><b>Mandataire : </b> Par défaut, un utilisateur n'a pas de mandataire. Un mandataire peut être définis pour permettre à un autre participant de voter à sa place.<br>
                    Le mandataire sera affiché lors de l'exportation des votes.</li>
                    <li><b>Nouveau mandataire : </b> Vous pouvez définir un nouveau mandataire pour un utilisateur. Si un mandataire est déjà défini, ce dernier sera remplacé.</li>
                </ul>
                <br>
                Vous ne pouvez pas modifier cette page si l'évènement est en cours ou terminé.
                </div>
            </div>
            </v-timeline-item>
            <!-- Votes -->
            <v-timeline-item
            color="primary"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    Votes
                </h3>
                <div>
                    Si le vote est en cours, une étiquette <v-chip class="mx-2" color="amber" text-color="white">En cours</v-chip> apparaitra. Vous pouvez à tout moment cloturer le vote s'il ne s'est pas cloturé automatiquement en cliquant sur "Cloturer le vote"<br><br>
                    Si le vote est terminé, une étiquette <v-chip class="mx-2" color="green" text-color="white">Cloturé</v-chip> apparaitra et vous pourrez voir les résultats.<br><br>
                    Vous pouvez à tout moment exporter les résultats sous format .pdf ou .xml en cliquant sur les icones en haut à droite 
                    <v-btn class="mx-2" fab dark small color="#1D6F42">
                        <v-icon dark> mdi-microsoft-excel </v-icon>
                    </v-btn> 
                    ou 
                    <v-btn class="mx-2" fab small dark color="#F40F02">
                        <v-icon dark> mdi-file-pdf-box </v-icon>
                    </v-btn>
                    <br><br>
                    Vous ne pouvez pas modifier cette page si l'évènement est en cours ou terminé.
                </div>
            </div>
            </v-timeline-item>
        </v-timeline>

    </div>
</template>

<script>
export default {
    name: 'HomeHelp'
}
</script>
