<template>
    <div>
        <div>
            <h4 class="py-2">Qu'est-ce que les liens RTMP ?</h4>
            <p>
                Les liens RTMP sont des chaines de caractères qui vous permet d'identifier votre chaîne Youtube ou Twitch. <br>
                Un lien RTMP peut être regénéré à tout moment sur les plateformes de Youtube ou Twitch. <br>
                Une fois que le lien RTMP est renseigné sur cette page, vous serez libre d'activer le Streaming lors d'un meeting, vous n'aurez aucune autre démarche supplémentaire.
            </p>
        </div>
        <div> 
            Comment obtenir votre lien RTMP ?
        </div>

        <v-timeline
            dense
            clipped
            class="mt-5"
        >
            <!-- Participants -->
            <v-timeline-item
            color="#FF0000"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    <v-icon left color="#FF0000"> mdi-youtube </v-icon>
                    Youtube
                </h3>
                <div>
                    <ol>
                        <li>Si ce n'est pas déjà fait, vous devez vous <a href="https://accounts.google.com/signin/v2/identifier">connecter</a> à votre compte gmail.</li>
                        <li>Rendez-vous ensuite sur le lien <a href="https://studio.youtube.com/channel/token/livestreaming" target="_blank">studio.youtube.com</a></li>
                        <li>Si une fenêtre de bienvenue s'affiche, cliquez sur "<b>Commencer</b>" dans la partie "<b>Plus tard</b>"</li>
                        <li>Si la fenêtre de bienvenue vous emmène sur une deuxième étape, cliquez sur "<b>Logiciel de streaming</b>"</li>
                        <li>Sur le menu latéral gauche, cliquez sur le premier menu "<b>Diffusion en streaming</b>", représenté par un icone en forme d'antenne.</li>
                        <li><b>Bravo</b> ! La configuration est terminé, maintenant vous pouvez récupérer la "<b>Clé de flux</b>" représenté par des "*******" en cliquant simplement sur le bouton "<b>Copier</b>" à sa droite.</li>
                        <li>Ensuite, collez cette clé dans le "<b>Lien RTMP Youtube</b>" de Stromeety.</li>
                    </ol>
                    <br>
                    <i>exemple de Clé RTMP Youtube : A1b2-c4D5-Efg6-7h8j</i>
                </div>
            </div>
            </v-timeline-item>
            <!-- Votes -->
            <v-timeline-item
            color="#6441a5"
            small
            >
            <div class="py-4">
                <h3 :class="`headline font-weight-light mb-4 primary--text`">
                    <v-icon left color="#6441a5"> mdi-twitch </v-icon>
                    Twitch
                </h3>
                <div>
                    <ol>
                        <li>Si ce n'est pas déjà fait, vous devez vous <a href="https://www.twitch.tv/">connecter / créer</a> un compte Twitch.</li>
                        <li>Rendez-vous ensuite sur votre <a href="https://dashboard.twitch.tv/" target="_blank">Dashboard</a></li>
                        <li>Sur le menu latéral gauche, cliquez sur "<b>Paramètres</b>" puis "<b>Flux</b>".</li>
                        <li>Vous trouverez votre "<b>Clé de streaming principale</b>". Cliquez sur le bouton "<b>Copier</b>" à droite</li>
                        <li>Ensuite, collez cette clé dans le "<b>Lien RTMP Twitch</b>" de Stromeety.</li>
                    </ol>
                    <br>
                    <i>exemple de Clé RTMP Twitch : live_8494894_OjifoehfooiHDIzoHOOI</i>
                </div>
            </div>
            </v-timeline-item>
        </v-timeline>
    </div>
</template>

<script>
export default {
    name: 'SettingsHelp'
}
</script>
