<template>
    <div
      v-if="!$route.path.includes('login') && !$route.path.includes('confirmation') && !$route.path.includes('password')"
    >
      <div id="help-button" v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
        <v-btn
          class="mx-1"
          fab
          dark
          color="primary"
          @click="helpDialogAction()"
        >
          <v-icon dark>
            mdi-help
          </v-icon>
        </v-btn>
      </div>

        <v-btn icon @click="helpDialogAction()" v-if="$vuetify.breakpoint.smAndDown">
            <v-icon>mdi-help</v-icon>
        </v-btn>

        <v-dialog
        v-model="helpDialog"
        transition="dialog-bottom-transition"
        max-width="800"
        >
        <v-card>
            <v-toolbar
                color="primary"
                class="title pl-5"
                dark
            >Page d'aide</v-toolbar>
            
            <v-card-text class="py-5">
              <HomeHelp v-if="this.$route.name == 'home'"></HomeHelp>
              <EventHelp v-if="this.$route.name == 'events'"></EventHelp>
              <VoteHelp v-if="this.$route.name == 'vote'"></VoteHelp>
              <SettingsHelp v-if="this.$route.name == 'setting'"></SettingsHelp>
            </v-card-text>
            <v-card-actions>
              <p class="caption" style="color:rgba(0, 0, 0, 0.6)">Vous avez des questions ou des remarques ? N'hésitez pas à contacter l'équipe de Stromeety. <a href="mailto:support@stromeety.com">support@stromeety.com</a></p>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
import HomeHelp from './help/HomeHelp'
import EventHelp from './help/EventHelp'
import VoteHelp from './help/VoteHelp'
import SettingsHelp from './help/SettingsHelp'

export default {
    name: 'HelpPage',
    components: {
        HomeHelp,
        EventHelp,
        VoteHelp,
        SettingsHelp
    },
    data: () => ({
      helpDialog: false
    }),
    methods: {
      helpDialogAction() {
        this.helpDialog = true
      }
    }
}
</script>

<style lang="scss">
#help-button {
  position: fixed;
  width: 15px;
  height: 15px;
  bottom: 75px;
  right: 75px;
}
</style>