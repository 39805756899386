<template>
  <div>
    <div>
      <v-divider class="ma-5"></v-divider>
      <div style="position:absolute; left:50%; right:5%" v-if="eventInfo && votes.length">
        <!-- Downloads icons -->
        <template>
          <DownloadVote :eventData="eventInfo" ref="download"></DownloadVote>
        </template>
      </div>
      <v-row>
        <v-col>
        <SuccessAlert ref="SuccessAlert"></SuccessAlert>
        <!-- Modal Création vote -->
          <v-dialog
            v-model="dialog"
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                :dark="!bloquePouvoir"
                class="mb-5"
                v-bind="attrs"
                v-on="on"
                :disabled="bloquePouvoir"
              >
                Créer un vote
              </v-btn>
            </template>

            <v-card>
                  <v-form
                    ref="form"
                    lazy-validation
                  >
                <v-toolbar
                  color="primary"
                  dark
                >
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                </v-toolbar>
              <v-card-text>
                <v-container>
                  <ErrorAlert ref="ErrorAlert"></ErrorAlert>
                  <v-alert
                    dense
                    text
                    type="info"
                    v-if="editedItem.anonyme"
                  >
                  {{ messageAnonyme }}
                  </v-alert>
                  
                    <v-row>
                      <v-col
                        cols="12"
                        md="8"
                        sm="12"
                      >
                        <v-text-field
                          v-model="editedItem.title"
                          label="Titre"
                          hint="Le titre vous permettra de retrouver votre question"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col 
                        cols="12"
                        md="4"
                        sm="12"
                      >
                        <v-checkbox
                          v-model="editedItem.anonyme"
                          label="Anonyme"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                      >
                        <v-text-field
                          v-model="editedItem.question"
                          label="Question"
                          append-outer-icon="mdi-help"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="5"
                      >
                        <v-select
                          v-model="editedItem.typeF"
                          :items="voteType"
                          label="Type de réponses"
                          :rules="requiredRule"
                          v-if="editedIndex > -1"
                        ></v-select>
                        <v-select
                          v-model="editedItem.type"
                          :items="voteType"
                          label="Type de réponses"
                          :rules="requiredRule"
                          v-if="editedIndex == -1"
                        ></v-select>
                      </v-col>
                      <v-col 
                        cols="12"
                        md="4"
                        offset-md="3"
                      >
                        <v-text-field
                          v-model="editedItem.time"
                          label="Temps en secondes"
                          value="30"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Fermer
                </v-btn>
                <v-btn
                  color="secondary"
                  @click="validate"
                >
                  {{ formButton }}
                </v-btn>
              </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <!-- Resultats dialog -->
          <v-dialog
            v-model="dialogResults"
            max-width="1000px"
          >
            <v-card>
                <v-toolbar
                  color="primary"
                  dark
                >
                  <v-card-title>
                    <span class="headline">Résultats du vote</span>
                  </v-card-title>
                </v-toolbar>
              <v-card-text>
                <v-container>
                    <v-row>
                      <v-col>
                        {{ dialogResultsQuestion }}
                      </v-col>
                      <v-col class="text-right">
                        Date et heure du vote : {{ dialogResultsTime }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col>
                            Taux de participation de {{ resumeResultats.pourcentage}} ({{ resumeResultats.nbVotes }}/{{ resumeResultats.nbParticipants }})
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col col="4" class="text-center">
                            <b>Oui</b> : {{ resumeResultats.pourcentageYes }}% ({{ resumeResultats.nbYes }})
                          </v-col>
                          <v-col col="4" class="text-center">
                            <b>Non</b> : {{ resumeResultats.pourcentageNo }}% ({{ resumeResultats.nbNo }})
                          </v-col>
                          <v-col col="4" class="text-center">
                            <b>S'abstient</b> : {{ resumeResultats.pourcentageAbstain }}% ({{ resumeResultats.nbAbstain }})
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-divider></v-divider>
                        <v-data-table
                          dense
                          :headers="headers"
                          :items="resultats"
                          item-key="id"
                          class="elevation-1"
                          v-if="dialogResultsAnonyme == false"
                        >
                          <template v-slot:[`item.username`]="{ item }">
                            {{ item.username }} ({{ item.ponderation }}) - <span v-if="item.isRepresentative">Voté par {{ item.representative }}</span>
                          </template>
                        </v-data-table>
                        <div v-else class="text-center my-5">Le vote est anonyme</div>
                      </v-col>
                    </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogResults = false"
                >
                  Fermer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Delete dialog -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 text-center">Êtes-vous sûr de vouloir supprimer ce vote ?</v-card-title>
              <v-card-actions class="d-flex justify-center">
                <v-btn color="secondary" text @click="closeDelete">Retour</v-btn>
                <v-btn color="secondary" @click="deleteItemConfirm">Supprimer</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <!-- Liste des questions -->
        <div v-if="votes.length">
          <v-expansion-panels >
            <v-expansion-panel v-for="vote in votes" :key="vote.id">
              <v-expansion-panel-header>
                <v-row no-gutters>
                  <v-col lg="4">
                    {{ vote.title }}
                  </v-col>
                  <v-col
                    cols="8"
                    class="text--secondary"
                  >
                    <v-fade-transition leave-absolute>
                      <v-row
                        no-gutters
                        style="width: 100%"
                      >
                        <v-col cols="12">
                          {{ vote.question }}
                          <span v-if="vote.status == 1"><v-chip class="mx-2" color="amber" text-color="white">En cours</v-chip></span>
                          <span v-if="vote.status == 2"><v-chip class="mx-2" color="green" text-color="white">Cloturé</v-chip></span>
                        </v-col>
                      </v-row>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  
                >
                  <v-col xl="3" lg="3" md="12" sm="12" xs="12" cols="false" >
                    Réponses possibles : {{ vote.typeF }}
                  </v-col>
                  <v-col xl="3" lg="3" md="12" sm="12" xs="12" cols="false" >
                    Durée du vote : {{ vote.time }} secondes
                  </v-col>
                  <v-col xl="3" lg="3" md="12" sm="12" xs="12" cols="false" >
                    Anonyme : {{ vote.anonymeF }}
                  </v-col>

                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    @click="showResults(vote)"
                    v-if="vote.status == 2"
                  >
                    Voir les résultats
                  </v-btn>
                  <v-btn
                    color="amber"
                    dark
                    @click="cloturer(vote)"
                    v-if="vote.status == 1"
                  >
                    Cloturer le vote
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="editItem(vote)"
                    v-if="vote.status == 0"
                  >
                    Modifier
                  </v-btn>
                  <v-btn
                    color="red"
                    dark
                    @click="deleteItem(vote)"
                  >
                    Supprimer
                  </v-btn>
                </v-card-actions>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-else class="text-center">
          Aucun vote n'a été créé.
        </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DownloadVote from './DownloadVote';
import SuccessAlert from "../alerts/Success.vue"
import ErrorAlert from "../alerts/Error.vue"

export default {
    name: 'Vote',
    components: {
      DownloadVote,
      SuccessAlert, 
      ErrorAlert
    },
    data: () => ({
      exportPdfClick: true,
      pdf: [],
      participants: [],
      dialog: false,
      dialogDelete: false,
      checkbox: false,
      dialogResults: false,
      eventName: "",
      dialogResultsQuestion: '',
      voteType: ['Oui / Non / S\'abstient'],
      requiredRule: [
          value => !!value || 'Requis.' 
      ],
      votes: [],
      resultats: [],
      headers: [
        { text: 'Nom / Prénom', value: 'username' },
        { text: 'Vote', value: 'answer' },
      ],
      editedItem: {
        id: '',
        title: '',
        question: '',
        time: '',
        anonyme: '',
        type: '',
        typeF: ''
      },
      editedIndex: -1,
      dialogResultsTime: "",
      resumeResultats: "",
      dialogResultsAnonyme: false,
      eventInfo: null,
      bloquePouvoir: false,
      messageAnonyme: 'Le vote étant anonyme, la pondération ne peut pas être mise en place.'
    }),

    created() {
      this.initialize()
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nouveau vote' : 'Modifier'
      },
      formButton() {
        return this.editedIndex === -1 ? 'Créer' : 'Modifier'
      },
    },
    methods: {
      initialize() {
        this.getList()
        this.$refs.form.resetValidation()
      },
      validate () {
        this.$refs.form.validate()
        this.createVote()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      editItem (item) {
        this.editedIndex = this.votes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.votes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        axios({
          method: 'delete',
          url: this.apiUrl+'/vote',
          headers: {
              'STROMEETY-AUTH-TOKEN': this.$globalData.token
          },
          data: {
            id: this.editedItem.id
          }
        })
        .then((response) => {
          this.votes.splice(this.editedIndex, 1)
          this.closeDelete()
          this.$refs.SuccessAlert.popAlert(response.data);
        }, (error) => {
          this.globalErrorMethod(error)
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
        });
      },

      getList() {
        axios({
          method: "get",
          url: this.apiUrl + "/votes/"+this.$route.params.id,
          headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
          }
        }).then(
          (response) => {
            this.eventInfo = response.data.event
            this.votes = response.data.votes
            this.eventName = response.data.event.name
            this.$emit('eventStatus', response.data.event.status);
          },
          (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
      },
      showResults(item) {
        this.dialogResultsQuestion = item.question
        this.dialogResultsAnonyme = item.anonyme
        axios({
          method: "get",
          url: this.apiUrl + "/vote/fullresultats",
          headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
          },
          params: {
            voteId: item.id,
          },
        }).then(
          (response) => {
              this.dialogResults = true,
              this.resultats = response.data.user_answer
              this.resumeResultats = response.data
              this.dialogResultsTime = response.data.start_datetime
          },
          (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
      },
      cloturer(item) {
        axios({
          method: "put",
          url: this.apiUrl + "/vote/cloture",
          headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
          },
          data: {
            voteId: item.id,
          },
        }).then(
          (response) => {
              this.$refs.SuccessAlert.popAlert(response.data);
              this.initialize();
          },
          (error) => {
            this.globalErrorMethod(error)
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      createVote() {
        // Update
        if (this.editedIndex > -1) {
          axios({
            method: "put",
            url: this.apiUrl + "/vote",
            headers: {
              'STROMEETY-AUTH-TOKEN': this.$globalData.token
            },
            data: {
              eventId: this.$route.params.id,
              id: this.editedItem.id,
              title: this.editedItem.title,
              question: this.editedItem.question,
              anonyme: this.editedItem.anonyme,
              type: this.editedItem.typeF,
              time: this.editedItem.time,
            },
          }).then(
            (response) => {
              this.$refs.SuccessAlert.popAlert(response.data);
              this.dialog = false
              this.initialize();
            },
            (error) => {
              this.globalErrorMethod(error)
              this.$refs.ErrorAlert.popAlert(error.response.data.error);
            }
          );
        } else { // Create
          axios({
            method: "post",
            url: this.apiUrl + "/vote",
            headers: {
              'STROMEETY-AUTH-TOKEN': this.$globalData.token
            },
            data: {
              eventId: this.$route.params.id,
              title: this.editedItem.title,
              question: this.editedItem.question,
              anonyme: this.editedItem.anonyme,
              type: this.editedItem.type,
              time: this.editedItem.time,
            },
          }).then(
            (response) => {
              this.$refs.SuccessAlert.popAlert(response.data);
              this.dialog = false
              this.initialize();
            },
            (error) => {
              this.globalErrorMethod(error)
              this.$refs.ErrorAlert.popAlert(error.response.data.error);
            }
          );
        }
      },
      setStatus(status) {
        if (status == 0) {
          this.bloquePouvoir = true
        } else {
          this.bloquePouvoir = false
        }
      }
    },
  }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
