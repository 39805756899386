<template>
    <div>
      <v-img
        src="@/assets/images/logo_full.png"
        class="my-10"
        height="250"
        contain
      ></v-img>
      <p class="text-center text-h6 font-weight-bold">Cette page n'existe pas.</p>
    </div>
</template>

<script>
export default {
    name: 'NotFoundPage'
}
</script>
