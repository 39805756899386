<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-img
              src="@/assets/images/logo_full.png"
              class="mb-10"
              height="200"
              contain
            ></v-img>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title class="mx-3">Confirmez votre inscription</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <ErrorAlert ref="ErrorAlert"></ErrorAlert>
                <v-col cols="12">
                  <span class="text-small">Votre compte a été créé mais n'est pas encore actif. Veuillez confirmer votre inscription en complétant le formulaire ci-dessous.</span>
                </v-col>
                <v-form @submit.prevent="submitInscription">
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="user.email"
                      prepend-icon="mdi-account"
                      name="email"
                      label="Email *"
                      type="text"
                      :disabled="true"
                      :class="{ 'is-invalid': submitted && !email }"
                    >
                    </v-text-field>
                    <div v-show="submitted && !email" class="invalid-feedback">
                      Email nécessaire
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="user.name"
                      prepend-icon="mdi-account"
                      name="name"
                      label="Nom *"
                      type="text"
                      :class="{ 'is-invalid': submitted && !name }"
                    >
                    </v-text-field>
                    <div v-show="submitted && !name" class="invalid-feedback">
                      Nom nécessaire
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="user.firstname"
                      prepend-icon="mdi-account"
                      name="firstname"
                      label="Prénom *"
                      type="text"
                      :class="{ 'is-invalid': submitted && !firstname }"
                    >
                    </v-text-field>
                    <div v-show="submitted && !firstname" class="invalid-feedback">
                      Prénom nécessaire
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="user.phone"
                      prepend-icon="mdi-cellphone"
                      name="phone"
                      label="Téléphone (optionnel)"
                      type="text"
                      counter
                      :rules="[phoneRules.required, phoneRules.min, phoneRules.integer]"
                      :class="{ 'is-invalid': submitted && !phone }"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="user.city"
                      prepend-icon="mdi-city"
                      name="city"
                      label="Ville (optionnel)"
                      type="text"
                      :class="{ 'is-invalid': submitted && !city }"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="user.password"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Mot de passe *"
                      counter
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      :rules="[rules.required, rules.min]"
                      :class="{ 'is-invalid': submitted && !password }"
                      @click:append="show1 = !show1"
                      autocomplete="new-password"
                    ></v-text-field>
                    <div v-show="submitted && !password" class="invalid-feedback">
                      Mot de passe nécessaire
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="user.rePassword"
                      prepend-icon="mdi-lock"
                      name="rePassword"
                      label="Confirmation du mot de passe *"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      counter
                      :rules="[rules.required, rules.min, passwordConfirmationRule]"
                      :class="{ 'is-invalid': submitted && !rePassword }"
                      @click:append="show2 = !show2"
                      autocomplete="new-password"
                    ></v-text-field>
                    <div v-show="submitted && !rePassword" class="invalid-feedback">
                      Confirmation du mot de passe nécessaire
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-checkbox
                      v-model="user.checkbox"
                      label="J'accepter les conditions d'utilisation"
                    ></v-checkbox>
                  </v-col>
                  <v-card-actions>
                    <div v-if="error" class="alert alert-danger">
                      {{ error }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" type="submit" :disabled="loading"
                      >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card-text>
              <v-progress-linear
                v-show="loading"
                color="primary"
                indeterminate
                reverse
              ></v-progress-linear>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import ErrorAlert from "./alerts/Error.vue"

export default {
    name: 'ValidationPage',
    components: { 
      ErrorAlert
    },
    data () {
        return {
            show1: false,
            show2: false,
            user: {
                name: '',
                firstname: '',
                email: '',
                phone: '',
                city: '',
                password: '',
                rePassword: '',
                checkbox: false
            },
            phoneRules: {
                min: v => v.length == 10 || 'Numéro de téléphone incorrect (10 chiffres nécessaires)',    
                integer: v => Number.isInteger(Number(v)) || 'Numéro de téléphone incorrect',            
            },
            rules: {
                required: value => !!value || 'Requis.',
                min: v => v.length >= 8 || 'Min 8 caractères',
            },
        }
    },
    created () {
      this.initialize()
    },
    computed: {
        userParams() {
            const params = new URLSearchParams();
            params.append('id', this.$route.params.code);
            return params;
        },
        validationParam() {
          const params = new URLSearchParams();
          params.append('name', this.user.name);
          params.append('firstname', this.user.firstname);
          params.append('email', this.user.email);
          params.append('phone', this.user.phone);
          params.append('city', this.user.city);
          params.append('password', this.user.password);
          params.append('rePassword', this.user.rePassword);
          params.append('checkbox', this.user.checkbox);
          return params;
        }
    },
    methods: {
      initialize () {
        axios.get(this.apiUrlAuth+"validation", {
          params: this.userParams
        })
        .then((response) => {
          this.user.email = response.data[0].email
          this.user.name = response.data[0].name
          this.user.firstname = response.data[0].firstname
        }, (error) => {
          this.$router.push('/login?error='+error.response.data.error);
        });
      },
      submitInscription() {
        axios({
          method: "get",
          url: this.apiUrlAuth+"validation/user",
          params: this.validationParam
        }).then(
          (response) => {
            this.$router.push('/login?success='+response.data);
          },
          (error) => {
            this.$refs.ErrorAlert.popAlert(error.response.data.error);
          }
        );

      }
    }
}
</script>