<template>
  <div>
    <v-btn
        dark
        small
        color="primary"
        @click="callVoteList()"
        style="width: 100%"
    >
    <v-icon left small> mdi-vote-outline </v-icon>
    Lancer un vote
    </v-btn>

    <!-- Liste des votes -->
    <v-dialog
    v-model="dialogVoteList"
    transition="dialog-top-transition"
    max-width="400"
    >
      <v-card>
        <v-toolbar
            color="primary"
            class="text-h6 pl-3"
            dark
        >Choisissez la question</v-toolbar>
        <v-card-text>
          <ErrorAlert ref="ErrorAlert"></ErrorAlert>
          <v-alert
            dense
            text
            type="info"
            class="mt-5"
            v-if="disabledVotes"
          >
          {{ messageVoteExist }}
          </v-alert>
          <v-select
            v-model="votesModel"
            :items="votes"
            :disabled="disabledVotes"
            item-text="title"
            item-value="id"
            label="Ma question"
            item-disabled="status"
          >
          </v-select>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn
            color="secondary"
            @click="publishVote(votesModel)"  
            :disabled="disabledVotes"   
            :dark="!disabledVotes"    
            >Lancer le vote</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ErrorAlert from "../../alerts/Error.vue"

export default {
  name: "StartVote",
  components: {  
    ErrorAlert
  },
  data: () => ({
    votesModel: null,
    dialogVoteList: false,
    votes: [],
    disabledVotes: false,
    messageVoteExist: "Un vote est déjà en cours"
  }),
  methods: {
    // On lance l'appel axios dans MeetingPage
    callVoteList() {
        this.disabledVotes = false;
        this.votesModel = null
        this.dialogVoteList = true
        this.$emit('loadVotes');
    },
    // On ajoute les votes dans this.votes
    loadVoteList (data) {
      data.forEach((value) => {
        if (value.status == 1) {
          this.disabledVotes = true;
        }
      });
      this.votes = data
    },
    // On publie le vote
    publishVote(item) {
      this.dialogVoteList = false

      axios({
        method: "put",
        url: this.apiUrl + "/vote/startVote",
        headers: {
          "STROMEETY-AUTH-TOKEN": this.$globalData.token,
        },
        data: {
          idVote: item,
          idEvent: this.$route.params.id,
        },
      }).then(
        () => {
          this.infoAlertControl = true;
          this.dialogVoteList = false
          this.voteEtat = 1; // 0 = pas de votes ; 1 = vote en cours
          this.infoMessageControl = "Un vote est en cours";
        },
        (error) => {
          this.globalErrorMethod(error);
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
        }
      );
    },
  }
};
</script>
