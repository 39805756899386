<template>
  <div>
    <v-card height="100%">
      <v-toolbar dark color="primary">
          <v-card-title><span class="headline text-no-wrap">Mes évènements à venir</span></v-card-title>
      </v-toolbar>
      <v-card-text>
          <v-timeline
              align-top
              dense
              v-if="list.length > 0"
              >
              <v-timeline-item
                v-for="(event, key) in list.slice(0, 4)"
                :key="key"
                small
              >
                  <div>
                  <div class="font-weight-normal">
                      <strong>{{ event.name }}</strong>
                  </div>
                  <div>{{ event.dateF }}<br>Durée : {{event.durationF}}<br> Salon : {{event.room}}</div>
                  </div>
              </v-timeline-item>
            </v-timeline>
            <div v-else>
              <span>Vous n'avez aucun évènement à venir</span>
            </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Events',
    props: {
      events: Array,
    },
    data: () => ({
      noEvent: true,
      list: []
    }),
    mounted: function () {
      this.sortEvents();
    },
    methods: {
      sortEvents() {
        this.events.forEach((value) => {
          if (value.status == 2) {
            this.list.push(value);
          }
        });
      }
    }
  }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

</style>
