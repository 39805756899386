<template>
  <v-card>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Rechercher"
      single-line
      v-if="$vuetify.breakpoint.xsOnly"
    ></v-text-field>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="membres"
      item-key="email"
      :search="search"
      checkbox-color="secondary"
      show-select
      v-if="$vuetify.breakpoint.xsOnly"
    >
    </v-data-table>
    <div v-if="!$vuetify.breakpoint.xsOnly" >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
        ></v-text-field>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="membres"
          item-key="email"
          :search="search"
          checkbox-color="secondary"
          show-select
          mobile-breakpoint="0"
        >
        </v-data-table>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn  @click="previous" text> Retour </v-btn>
      <v-btn color="secondary" @click="validate"> Suivant </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "Step2",
  data() {
    return {
      selected: [],
      search: "",
      e1: 2,
      headers: [
        { text: "", sortable: false, value: "data-table-select", class:'primary' },
        {
          text: "Nom / Prénom",
          align: "center",
          value: "fullname",
          class:'primary white--text'
        },
      ],
      membres: [],
    };
  },
  created() {
    this.initialize();
  },

  methods: {
    resetForm() {
      this.selected = []
    },
    initialize() {
        axios({
          method: "get",
          url: this.apiUrl + "/membres",
          headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
          },
        }).then(
          (response) => {
            this.membres = response.data
          },
          (error) => {
            this.globalErrorMethod(error)
          }
        );
    },
    validate () {
      this.$emit('changeEl', '3');
      this.$emit('stepTwo', this.selected);
    },
    previous () {
      this.$emit('changeEl', '1');   
    }
  },

};
</script>