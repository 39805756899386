<template>
  <div>
    <div>
      <!-- Evenements actuels et à venir -->
      <h2 class="text-center mb-5">Evènements en cours et à venir.</h2>
      <v-divider class="ma-5"></v-divider>
      <ErrorAlert ref="ErrorAlert"></ErrorAlert>
      <v-row v-if="activeEvents.length == 0">
        <v-col cols="12" class="text-center">
          <span>Aucun évènements à venir</span>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-wrap" >
        <v-col lg="4" md="12" sm="12" v-for="event in activeEvents" :key="event.id">
        <v-card
            class="mt-4 mr-4"
            width="100%"
            :style="event.status == 1 ? 'box-shadow: 1px 1px 7px 1px #006EB2' : ''"
          >
            <v-toolbar class="primary" dark>
              <v-card-title>{{event.name}}</v-card-title>
              <v-spacer></v-spacer>
              <v-chip
                color="white"
                class="mr-2"
                text-color="rgba(0, 0, 0, 0.6)"
              >
                {{ getNameEtat(event.status) }}
              </v-chip>
            </v-toolbar>
            <v-card-text>
              <p>Le {{ event.dateF }}<br>
              Salon : {{ event.room }}</p>
              <div class="text--primary" v-if="event.description != null">
                {{ event.description }}
              </div>
              <div class="text--primary" v-else>
                Aucune description
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn  color="secondary" :to="{ name: 'vote', params: { id: event.id }}">
                Accéder
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <!-- Evenements terminés -->
      <h2 class="text-center my-5">Evènements terminés.</h2>
      <v-divider class="ma-5"></v-divider>
      <v-data-table
        :headers="headersEvents"
        :items="oldEvents"
        fixed-header	
        locale="fr"
        v-if="!$vuetify.breakpoint.xsOnly"
      >
        <template v-slot:top>
          <v-toolbar flat height="0">
          </v-toolbar>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            :color="getColor(item.status)"
            dark
          >
            {{ getNameEtat(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date }} à {{ item.time }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" title="Voir le détail" @click="showVote(item)"> mdi-calendar </v-icon>
        </template>
      </v-data-table>
      <!-- Mobile v-data-table -->
      <v-data-iterator
        :items="oldEvents"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        class="mb-15"
        v-if="$vuetify.breakpoint.xsOnly"
        hide-default-footer
      >
        <template v-slot:item="props">
          <v-card 
            class="my-5"
            hover
          >
            <v-toolbar color="primary" dark>
              <v-card-title>{{props.item.name}}</v-card-title>
            </v-toolbar>
            <v-card-text>
              {{props.item.room}}<br>{{props.item.dateF}}<br>{{props.item.durationF}}
            </v-card-text>
            <v-card-actions>
              <v-chip
                :color="getColor(props.item.status)"
                :style="
                  props.item.status == 1 ? {'box-shadow': '1px 1px 7px 3px #4CAF50'} : {}
                "
                dark
              >
                {{ getNameEtat(props.item.status) }}
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn icon title="Voir le détail" @click="showVote(props.item)">
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:footer>
          <v-row
            class="my-3"
            align="center"
            justify="center"
            style="bottom:0; width:100%;"
          >
            <span
              class="mr-4
              grey--text"
            >
              Page {{ page }} sur {{ numberOfPages }}
            </span>
            <v-btn
              fab
              small
              color="secondary"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              color="secondary"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ErrorAlert from "../alerts/Error.vue"

export default {
  name: 'Table',
  components: {  
    ErrorAlert
  },
  data: () => ({
    image: "@/assets/images/logo_white.png",
    headersEvents: [
      { text: "Etat", value: "status", class:"primary white--text" },
      { text: "Evènements", value: "name", class:"primary white--text" },
      { text: "Salons", value: "room", class:"primary white--text" },
      { text: "Date de session", value: "dateF", class:"primary white--text" },
      { text: "Durée", value: "duration", class:"primary white--text" },
      { text: "Type d'évènement", value: "type", class:"primary white--text" },
      { text: "Actions", value: "actions", sortable: false, class:"primary white--text" },
    ],
    noEvents: false,
    events: [],
    oldEvents: [],
    activeEvents: [],
    itemsPerPageArray: [4, 8, 12],
    page: 1,
    itemsPerPage: 10
  }),

  created () {
    this.initialize()
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.events.length / this.itemsPerPage)
    }
  },
  methods: {
    initialize () {
      axios({
        method: 'get',
        url: this.apiUrl+'/evenements/list',
        headers: {
            'STROMEETY-AUTH-TOKEN': this.$globalData.token
        }
      })
      .then((response) => {
        this.events = response.data;
        if (response.data.length == 0) {
          this.noEvents = true;
        }
        this.sortEvents();
      }, (error) => {
        this.globalErrorMethod(error)
        this.$refs.ErrorAlert.popAlert(error.response.data.error);
      });
    },
    sortEvents () {
      this.events.forEach((value) => {
        if (value.status == 2) {
          this.activeEvents.push(value);
        }
        else if (value.status == 1) {
          this.activeEvents.push(value);
        }
        else if (value.status == 0) {
          this.oldEvents.push(value);
        }
      });
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    showVote (item) {
      this.$router.push({name: 'vote', params: { id: item.id }});
    },
    getColor (status) {
      if (status == 0) return 'grey'
      else if (status == 1) return 'green'
      else if (status == 2) return 'primary'
      else return 'red'
    },

    getNameEtat (status) {
      if (status == 0) return 'Terminé'
      else if (status == 1) return 'En cours'
      else if (status == 2) return 'À venir'
      else return 'Erreur'        
    },
  },
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
