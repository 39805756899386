<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md3>
              <v-img
                src="@/assets/images/logo_full.png"
                class="mb-10"
                height="200"
                contain
              ></v-img>
                  <v-alert type="error" color="red lighten-1" border="left" v-model="errorUrlAlert">
                    {{ errorUrl }}
                  </v-alert>
                  <v-alert type="success" color="green lighten-1" border="left" v-model="successUrlAlert">
                    {{ successUrl }}
                  </v-alert>
              <!--  -->
              <transition name="fade">
              <v-card class="elevation-12" key="login" v-if="login">
                <v-toolbar dark color="primary">
                  <v-toolbar-title class="px-3">Se connecter</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <ErrorAlert ref="ErrorAlert"></ErrorAlert>
                  <v-form @submit.prevent="handleSubmit">
                    <v-text-field
                      v-model="email"
                      prepend-icon="mdi-account"
                      name="email"
                      label="Identifiant"
                      type="email"
                      :error="submitted && !email"
                      :class="{ 'is-invalid': submitted && !email }"
                    >
                    </v-text-field>

                    <v-text-field
                      v-model="password"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Mot de passe"
                      :error="submitted && !password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      :class="{ 'is-invalid': submitted && !password }"
                      @click:append="show1 = !show1"
                    ></v-text-field>

                    <!-- Forgot password -->

                    <v-card-actions>
                    <div>
                      <a class="text-decoration-none" @click="resetPasswordVue()" style="color: rgba(0, 0, 0, 0.6);">Mot de passe oublié ?</a>
                    </div>
                      <div v-if="error" class="alert alert-danger">
                        {{ error }}
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" type="submit" :disabled="loading"
                        >Se connecter</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card-text>
                <v-progress-linear
                  v-show="loading"
                  color="primary"
                  indeterminate
                  reverse
                ></v-progress-linear>
              </v-card>
              <!--  -->
              <v-card class="elevation-12" key="forgotPassword" v-else>
                <v-toolbar dark color="primary">
                  <v-toolbar-title class="px-3">Mot de passe oublié</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <ErrorAlert ref="ErrorAlert"></ErrorAlert>
                  <v-form @submit.prevent="forgotPasswordSubmit()">
                    <span class="caption">Vous allez recevoir un mail avec la procédure pour réinitialiser votre mot de passe.</span>
                    <v-text-field
                      v-model="emailResetPassword"
                      prepend-icon="mdi-account"
                      name="emailResetPassword"
                      label="Adresse email"
                      type="email"
                      :class="{ 'is-invalid': submitted && !email }"
                      @blur="validateEmail"
                    >
                    </v-text-field>

                    <div v-show="submitted && !password" class="invalid-feedback">
                      Mot de passe nécessaire
                    </div>
                    <!-- Forgot password -->

                    <v-card-actions>
                    <div>
                      <a class="text-decoration-none" @click="loginVue()" style="color: rgba(0, 0, 0, 0.6);">Se connecter</a>
                    </div>
                      <div v-if="error" class="alert alert-danger">
                        {{ error }}
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" type="submit"
                        >Réinitialiser</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card-text>
                <v-progress-linear
                  v-show="loading"
                  color="primary"
                  indeterminate
                  reverse
                ></v-progress-linear>
              </v-card>
              </transition>
              <!--  -->
            </v-flex>
          </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { userService } from "../_services/user.service";

import ErrorAlert from "./alerts/Error.vue"

import axios from "axios";

export default {
  name: 'LoginPage',
  components: { 
      ErrorAlert
  },
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      errorAlert: false,
      email: "",
      errorUrl: "",
      errorUrlAlert: false,
      successUrl: "",
      successUrlAlert: false,
      password: "",
      submitted: false,
      loading: false,
      returnUrl: "",
      return: "",
      error: "",
      login: true,
      emailResetPassword: ""
    };
  },
  created() {
    // reset login status
    userService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.$route.query.returnUrl || "/";

    if (this.$route.query.error) {
      this.errorUrlAlert = true;
      this.errorUrl = this.$route.query.error;
    }
    if (this.$route.query.success) {
      this.successUrlAlert = true;
      this.successUrl = this.$route.query.success;
    }
  },
  computed: {
      axiosParams() {
          const params = new URLSearchParams();
          params.append('email', this.email);
          params.append('password', btoa(this.password));
          return params;
      }
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      const { email, password } = this;

      if (!(email && password)) {
        return;
      }

      this.loading = true;

      axios.get(this.apiUrlAuth+"auth", {
        params: this.axiosParams
      })
      .then((response) => {
        this.user = response.data;
        localStorage.setItem("user", JSON.stringify(this.user));
        this.$globalData.isAdmin = response.data.isAdmin;
        this.$globalData.token = response.data.token;
        this.$router.push(this.returnUrl);
      }, (error) => {
          this.loading = false;
          this.$refs.ErrorAlert.popAlert(error.response.data.message);
      });
    },
    forgotPasswordSubmit() {
      axios.get(process.env.VUE_APP_AUTH_FORGOT_PASSWORD, {
        params: {
          email : this.emailResetPassword
        }
      })
      .then((response) => {
          this.successUrlAlert = true;
          this.successUrl = response.data;
          this.login = true;
      }, (error) => {
          this.$refs.ErrorAlert.popAlert(error.response.data.error);
      });
    },
    resetPasswordVue() {
      this.login = false
    },
    loginVue() {
      this.login = true
    }
  }
}
</script>
<style lang="scss">

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}
.fade-enter-active {
  transition-delay: .25s;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>
